import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { GET_JOB_POSTINGS_FOR_VIEWER } from "../../../behavior/queries/jobpostings.query";
import { JobPosting } from "../../../types/jobPosting";
import { Heading, HorizontalLine } from "../../elements";
import JobPostingsList from "./JobPostingsList";

const Header = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="title-section">
				<Heading level="h5">{t("Dashboard.LatestJobPostings.Title")}</Heading>
			</div>
			<HorizontalLine className="mb-5" weight="semi-dark" />
			<Heading level="h4" light>
				{t("Dashboard.LatestJobPostings.Subtitle")}
			</Heading>
		</>
	);
};

interface JobPostingsForViewer {
	viewer: {
		recommendedJobs: JobPosting[];
	};
}

export default function LatestJobPostings() {
	const PAGE_SIZE = 20;

	const { loading, error, data } = useQuery<JobPostingsForViewer>(
		GET_JOB_POSTINGS_FOR_VIEWER,
		{
			variables: {
				top: PAGE_SIZE,
			},
		}
	);

	const renderList = () => {
		if (loading) {
			return (
				<JobPostingsList
					loading={loading}
					error={undefined}
					jobPostings={undefined}
				/>
			);
		}

		if (error) {
			return (
				<JobPostingsList
					loading={loading}
					error={error}
					jobPostings={undefined}
				/>
			);
		}

		return (
			<JobPostingsList
				loading={loading}
				error={undefined}
				jobPostings={data?.viewer.recommendedJobs}
			/>
		);
	};

	return (
		<div className="latest-job-postings-wrapper">
			<Header />
			{renderList()}
		</div>
	);
}
