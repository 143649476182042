import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MiniLogoImage from "../../assets/images/hubert-logo-mini.png";
import BgImage from "../../assets/images/login-bg-image.png";
import { Heading, LargeAlert } from "../../components/elements";
import { LargeAlertProps } from "../../components/elements/LargeAlert";
import Header from "../../components/header/Header";
import LoginForm from "../../components/login/LoginForm";
import SocialMediaLogin from "../../components/login/SocialMediaLogin";
import { PageState, TPageState } from "../../constants";
import { LOGIN } from "../../utils/constants/heapIds";
import "./Login.scss";

const Login: FC = () => {
	const { t } = useTranslation();
	const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: "",
		subtitle: "",
		type: "info",
	});
	const [pageState, setPageState] = useState<TPageState>(PageState.SIGN_IN);
	const [email, setEmail] = useState("");
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const state = params.get("state") as TPageState;
		const userEmail = params.get("email");
		if (state) setPageState(state);
		if (userEmail) setEmail(userEmail);
	}, [location]);

	return (
		<>
			<Header type="Login" />
			<div className="login-wrapper">
				<div className="left-section">
					<Heading level="h2">{t("Login.MainTitle")}</Heading>
					<Heading level="h4" light>
						{t("Login.Subtitle")}
					</Heading>
					{!showAlert.title && (
						<img className="bg-image" src={BgImage} alt="Login background" />
					)}
				</div>
				<div className="right-section">
					<img
						className="hubert-logo-mini"
						src={MiniLogoImage}
						alt="Hubert logo mini"
					/>
					<Heading level="h4">{t("Login.LoginTitle")}</Heading>
					{showAlert.title ? (
						<LargeAlert
							title={showAlert.title}
							subtitle={showAlert.subtitle}
							type={showAlert.type}
						/>
					) : (
						<div>
							<LoginForm
								setShowAlert={setShowAlert}
								preLoadedEmail={email}
								state={pageState}
								heapIds={LOGIN}
							/>
							<div>
								<div className="or-section">
									<div className="or-seperator-line" />
									<div className="or-text">{t("Login.OrSeparator")}</div>
								</div>
								<SocialMediaLogin
									state={pageState}
									heapIds={LOGIN}
									preLoadedEmail={email}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Login;
