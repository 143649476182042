import { FC, MouseEventHandler } from 'react';
import { ReactComponent as CarouselPrevArrow } from '../../../assets/images/carousel-prev-icon.svg';
import { ReactComponent as CarouselNextArrow } from '../../../assets/images/carousel-next-icon.svg';

type CarouselLeftArrow = {
  className?: string
  style?: object
  onClick?: MouseEventHandler<HTMLButtonElement>
  left: true
};

type CarouselRightArrow = {
  className?: string
  style?: object
  onClick?: MouseEventHandler<HTMLButtonElement>
  right: true
};

export type CarouselArrowProps = CarouselLeftArrow | CarouselRightArrow;

const isLeftArrow = (props: any): props is CarouselLeftArrow => props.left;

const isRightArrow = (props: any): props is CarouselRightArrow => props.right;

const CarouselArrow: FC<CarouselArrowProps> = (props: CarouselArrowProps) => {
  const { className, style, onClick } = props;

  const renderArrow = () => {
    if (isLeftArrow(props)) {
      return <CarouselPrevArrow className="slick-arrow slick-next" />;
    }

    return isRightArrow(props) ? <CarouselNextArrow className="slick-arrow slick-prev" /> : null;
  };

  return (
    <div
      className={className}
      style={style}
    >
      <button type="button" onClick={onClick}>
        {renderArrow()}
      </button>
    </div>
  );
};

export default CarouselArrow;
