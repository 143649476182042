import { useMutation } from "@apollo/client";
import TransitionPage from "pages/transition/TransitionPage";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginStateWithAction } from "types/authentication";
import { FederatedSignInTokenResponse } from "../../behavior/graphTypes/authenticationTypes";
import { useAppDispatch } from "../../behavior/hooks";
import { SIGNIN_FEDERATED_LOGIN } from "../../behavior/mutations/auth.mutation";
import { setSession } from "../../behavior/reducers/sessionSlice";
import LargeAlert, {
	LargeAlertProps,
} from "../../components/elements/LargeAlert";
import {
	ApplicationLanguage,
	PageRoute,
	PageState,
	ProviderId,
	TokenAction,
} from "../../constants";
import { UserCredentialFederated } from "../../types/userCredential";
import { getCookie } from "../../utils/cookieHandlers";
import { decodeStateParam } from "../../utils/urlUtility";

const FacebookCallback = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const shouldExecute = useRef(true);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [federatedSignin] = useMutation<
		FederatedSignInTokenResponse,
		UserCredentialFederated
	>(SIGNIN_FEDERATED_LOGIN);
	const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: t("AppMessages.sign-in-title"),
		subtitle: t("AppMessages.sign-in-subtitle"),
		type: "loading",
	});

	const generateInterviewLink = (
		accessToken: string | undefined,
		interviewId: string
	): string => {
		const interviewUrl = process.env.REACT_APP_INTERVIEW_URL;
		const redirectUrl = encodeURIComponent(
			`${window.location.origin}${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`
		);
		return `${interviewUrl}/${interviewId}?token=${accessToken}&redirectUrl=${redirectUrl}`;
	};

	const exchangeFacebookIdentity = useCallback(
		async (
			code: string,
			options?: Partial<LoginStateWithAction> & {
				linkPassword?: boolean;
			}
		) => {
			try {
				const postInvitationStates = [
					PageState.INVITATION_POST_INTERVIEW_SIGN_UP,
					PageState.INVITATION_POST_INTERVIEW_SIGN_IN,
				] as const;
				const postJobApplyStates = [
					PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP,
					PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN,
				] as const;

				const language: string =
					getCookie("language") ?? ApplicationLanguage.EN_EU;
				const request: UserCredentialFederated = {
					provider: ProviderId.FACEBOOK,
					token: code,
					language,
				};

				if (options?.invitation) {
					request.email = options.invitation.invitee.email;
				}

				if (options?.email) {
					request.email = options.email;
				}

				const data = await federatedSignin({
					variables: request,
				});
				const response = data.data?.signInFederatedUser;
				if (!response) {
					toast.error(t("AppMessages.server-error"));
					return;
				}

				if (response.isError && response.errorMessage) {
					if (response.errorMessage === "email-mismatch") {
						if (options?.action === PageState.INVITATION_SIGN_IN) {
							setShowAlert({
								title: t(`AppMessages.${response.errorMessage}-title`),
								subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
								type: "warning",
								button: {
									label: t("SocialLoginCallback.ButtonText_TryAgain"),
									to: `${PageRoute.INVITATION_VERIFICATION}?token=${options?.token}`,
								},
							});
						} else if (
							options?.invitation &&
							postInvitationStates.find((x) => x === options?.action)
						) {
							const inviteeEmail = options.invitation.invitee.email;
							const retryUrl = new URL(
								PageRoute.POST_INTERVIEW_REGISTRATION,
								window.location.origin
							);

							if (options?.token)
								retryUrl.searchParams.append("token", options?.token);

							retryUrl.searchParams.append("email", inviteeEmail);
							retryUrl.searchParams.append("state", options?.action as string);

							if (
								options?.action === PageState.INVITATION_POST_INTERVIEW_SIGN_IN
							) {
								retryUrl.pathname = PageRoute.POST_INTERVIEW_LOGIN;
							}

							setShowAlert({
								title: t(`AppMessages.${response.errorMessage}-title`),
								subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
								type: "warning",
								button: {
									label: t("SocialLoginCallback.ButtonText_TryAgain"),
									to:
										retryUrl.pathname + "?" + retryUrl.searchParams.toString(),
								},
							});
						} else if (
							options?.email &&
							postJobApplyStates.find((x) => x === options?.action)
						) {
							setShowAlert({
								title: t(`AppMessages.${response.errorMessage}-title`),
								subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
								type: "warning",
								button: {
									label: t("SocialLoginCallback.ButtonText_TryAgain"),
									to: `${PageRoute.INSTANT_APPLY.INDEX}/${
										PageRoute.INSTANT_APPLY.POST_INTERVIEW
									}/?email=${encodeURIComponent(options.email)}`,
								},
							});
						}
						return;
					}
					toast.error(t(`AppMessages.${response.errorMessage}`));
				} else {
					dispatch(setSession(response.payload));
					if (
						options?.invitation &&
						options?.action === PageState.INVITATION_SIGN_IN
					) {
						const interviewStartUrl = generateInterviewLink(
							response.payload?.access_token,
							options?.invitation.interviewId
						);
						navigate(PageRoute.PRE_INTERVIEW, {
							replace: true,
							state: {
								interviewUrl: interviewStartUrl,
								invitation: options.invitation,
							},
						});
						return;
					}

					if (options?.linkPassword) {
						navigate(PageRoute.PROFILE, { replace: true });
						return;
					}

					if (
						options?.redirectUrl &&
						options?.email &&
						options?.action === PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN
					) {
						const redirectUrl = new URL(
							`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW}`,
							window.location.origin
						);

						redirectUrl.searchParams.append("email", options?.email);

						const interviewUrl = new URL(options.redirectUrl);
						interviewUrl.searchParams.set(
							"redirectUrl",
							redirectUrl.toString()
						);
						interviewUrl.searchParams.set(
							"token",
							response.payload.access_token
						);
						window.location.replace(interviewUrl.toString());
						return;
					}

					if (options?.redirectUrl) {
						navigate(options.redirectUrl, { replace: true });
						return;
					}

					if (
						postInvitationStates.find((x) => x === options?.action) ||
						postJobApplyStates.find((x) => x === options?.action)
					) {
						navigate(
							`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`,
							{ replace: true }
						);
						return;
					} else if (response.payload?.action === TokenAction.SIGNUP) {
						navigate(PageRoute.DASHBOARD, { replace: true, state: "new-user" });
						return;
					}

					navigate(PageRoute.DASHBOARD, { replace: true });
					return;
				}
			} catch (error: any) {
				toast.error(error.message);
			}
			navigate(PageRoute.ROOT, { replace: true });
		},
		[dispatch, federatedSignin, navigate, t]
	);

	useEffect(() => {
		if (!shouldExecute.current) {
			return;
		}

		shouldExecute.current = false;

		const params = new URLSearchParams(location.hash);
		if (params.has("#access_token")) {
			const token = params.get("#access_token");
			const action = params.get("state");
			if (action) {
				shouldExecute.current = false;
				const decodedState = decodeStateParam(action);
				if (token) {
					switch (decodedState.action) {
						case PageState.SIGN_UP:
						case PageState.SIGN_IN:
							exchangeFacebookIdentity(token, {
								redirectUrl: decodedState.redirectUrl,
								email: decodedState.email,
							});
							break;
						case PageState.INVITATION_POST_INTERVIEW_SIGN_IN:
						case PageState.INVITATION_POST_INTERVIEW_SIGN_UP:
							exchangeFacebookIdentity(token, {
								redirectUrl: decodedState.redirectUrl,
								invitation: decodedState.invitation || undefined,
								action: decodedState.action,
								token: decodedState.token,
							});
							break;
						case PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN:
						case PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP:
						case PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN:
							exchangeFacebookIdentity(token, {
								redirectUrl: decodedState.redirectUrl,
								email: decodedState.email,
								action: decodedState.action,
							});
							break;
						case PageState.INVITATION_SIGN_IN:
							if (decodedState.token) {
								exchangeFacebookIdentity(token, {
									invitation: decodedState.invitation,
									token: decodedState.token,
									redirectUrl: decodedState.redirectUrl,
								});
							} else {
								setShowAlert({
									title: t("AppMessages.server-error"),
									subtitle: t("AppMessages.invalid-invitation"),
									type: "warning",
								});
							}
							break;
						case PageState.LINK_PASSWORD:
							exchangeFacebookIdentity(token, {
								linkPassword: true,
								redirectUrl: decodedState.redirectUrl,
							});
							break;
						default:
							setShowAlert({
								title: t("AppMessages.server-error"),
								subtitle: t("AppMessages.internal-server-error"),
								type: "warning",
							});
							break;
					}
				}
			}
		} else {
			navigate(PageRoute.ROOT, { replace: true });
		}
	}, [exchangeFacebookIdentity, location.hash, navigate, t]);

	if (showAlert.type === "warning" || showAlert.type === "danger") {
		return <LargeAlert {...showAlert} />;
	}

	return (
		<TransitionPage title={showAlert.title} subtitle={showAlert.subtitle} />
	);
};

export default FacebookCallback;
