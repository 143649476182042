import { getTokens, isLogged } from "behavior/security/auth.service";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../behavior/hooks";
import { setSession } from "../../behavior/reducers/sessionSlice";
import { PageRoute } from "../../constants";

function skipSessionCheck(location: ReturnType<typeof useLocation>) {
	const skipRoutes = [
		PageRoute.GOOGLE_CALLBACK,
		PageRoute.FACEBOOK_CALLBACK,
		PageRoute.INVITATION_VERIFICATION,
		`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW}`,
		`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.PRE_INTERVIEW}`,
		PageRoute.DEMO,
		PageRoute.IMPERSONATION,
	];
	return skipRoutes.find((x) => location.pathname.startsWith(x));
}

const useHydrateUserSession = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const logged = isLogged();

	useEffect(() => {
		if (skipSessionCheck(location)) return;

		const { accessToken, refreshToken } = getTokens();
		if (accessToken && refreshToken && !isLogged()) {
				const payload = {
					access_token: accessToken,
					refresh_token: refreshToken,
					token_type: "Bearer",
					expires_in: 3600,
					action: "",
				};
				dispatch(setSession(payload));

			navigate(PageRoute.TRANSITION_PAGE, {
				state: { redirectUrl: `${location.pathname}${location.search}` },
			});
			return;
		}
	}, [dispatch, logged, location.pathname, location.search, navigate]);
};

export default useHydrateUserSession;
