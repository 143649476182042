import { gql } from "@apollo/client";

export const SIGNIN_EMAIL_PASSWORD = gql`
	mutation SIGNIN_EMAIL_PASSWORD($email: String!, $password: String!) {
		signInUsingPassword(email: $email, password: $password) {
			payload {
				access_token
				refresh_token
				token_type
				expires_in
			}
			errorMessage
			code
			isError
		}
	}
`;

export const SIGNUP_EMAIL_PASSWORD = gql`
	mutation SIGNUP_EMAIL_PASSWORD($registerUserInput: RegisterUserInput!) {
		signUpUsingPassword(registerUserInput: $registerUserInput) {
			payload
			errorMessage
			code
			isError
		}
	}
`;

export const SIGNUP_EMAIL_TOKEN = gql`
	mutation SIGNUP_EMAIL_TOKEN(
		$registerUserByTokenInput: RegisterUserByTokenInput!
	) {
		signUpUsingToken(registerUserByTokenInput: $registerUserByTokenInput) {
			errorMessage
			code
			isError
			payload {
				access_token
				refresh_token
				token_type
				expires_in
			}
		}
	}
`;

export const SIGNUP_EMAIL_RTS_TOKEN = gql`
	mutation SIGNUP_EMAIL_RTS_TOKEN(
		$registerUserByTokenInput: RegisterUserByTokenInput!
	) {
		signUpUsingRTSToken(registerUserByTokenInput: $registerUserByTokenInput) {
			errorMessage
			code
			isError
			payload {
				access_token
				refresh_token
				token_type
				expires_in
			}
		}
	}
`;

export const SIGNIN_FEDERATED_LOGIN = gql`
	mutation SIGNIN_FEDERATED_LOGIN(
		$provider: String!
		$token: String!
		$language: String
		$email: String
	) {
		signInFederatedUser(
			provider: $provider
			token: $token
			language: $language
			email: $email
		) {
			payload {
				access_token
				refresh_token
				token_type
				expires_in
				action
			}
			errorMessage
			code
			isError
		}
	}
`;

export const VERIFY_EMAIL = gql`
	mutation VERIFY_EMAIL($code: String!, $email: String!) {
		verifyEmail(code: $code, email: $email) {
			payload
			errorMessage
			code
			isError
		}
	}
`;

export const REFRESH_TOKEN = gql`
	mutation REFRESH_TOKEN($refreshToken: String!) {
		renewIdToken(refreshToken: $refreshToken) {
			payload {
				access_token
				refresh_token
				token_type
				expires_in
			}
			errorMessage
			code
			isError
		}
	}
`;

export const SIGNOUT_USER = gql`
	mutation SIGNOUT_USER{
		signOut {
			payload
			errorMessage
			code
			isError
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation RESET_PASSWORD($resetPasswordInput: ResetPasswordInput) {
		resetPassword(resetPasswordInput: $resetPasswordInput) {
			... on BooleanResponse {
				payload
				errorMessage
				code
				isError
			}
			... on PasswordNotSet {
				message
			}
		}
	}
`;

export const SET_PASSWORD_BY_CODE = gql`
	mutation SET_PASSWORD_BY_CODE($code: String!, $newPassword: String!) {
		setPasswordByCode(code: $code, newPassword: $newPassword) {
			... on BooleanResponse {
				payload
				errorMessage
				code
				isError
			}
			... on WeakPassword {
				message
			}
			... on InvalidCode {
				message
			}
		}
	}
`;
