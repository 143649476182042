import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Image, Text } from "../../elements";
import RoundIcon from "../../../assets/images/round-icon.png";

const NoRecentInterviews: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="section-body no-ongoing-interviews mt-4">
			<Image src={RoundIcon} alt="Round icon" />
			<div>
				<Text weight="semibold" block>
					{t("Dashboard.MyCareer.NoRecentInterviews_Title")}
				</Text>
				<Text size="small" block>
					{t("Dashboard.MyCareer.NoRecentInterviews_Subtitle")}
				</Text>
			</div>
		</div>
	);
};

export default NoRecentInterviews;
