import { isLogged } from "behavior/security/auth.service";
import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PageState } from "./constants";

const ProtectedRoute: FC = () => {
	const location = useLocation();
	if (!isLogged()) {
		const redirectUrl = encodeURIComponent(location.pathname);
		return (
			<Navigate
				to={`/?state=${PageState.SESSION_EXPIRED}&redirectUrl=${redirectUrl}`}
			/>
		);
	}
	return <Outlet />;
};

export default ProtectedRoute;
