export const textsKeysQuestions = {
  greatMatch: {
    description:
      'Interview.HowToImproveAtInterviews.GreatMatch_Questions_Description',
    descriptionSecondary: '',
  },
  okMatch: {
    description:
      'Interview.HowToImproveAtInterviews.OkMatch_Questions_Description',
    descriptionSecondary: '',
  },
  gotPotential: {
    description:
      'Interview.HowToImproveAtInterviews.GotPotential_Questions_Description',
    descriptionSecondary: '',
  },
  notClearMatch: {
    description:
      'Interview.HowToImproveAtInterviews.NotClearMatch_Questions_Description',
    descriptionSecondary: '',
  },
};

export const textsKeysNoQuestions = {
  greatMatch: {
    description:
      'Interview.HowToImproveAtInterviews.GreatMatch_NoQuestions_Description',
    descriptionSecondary: '',
  },
  okMatch: {
    description:
      'Interview.HowToImproveAtInterviews.OkMatch_NoQuestions_Description',
    descriptionSecondary:
      'Interview.HowToImproveAtInterviews.OkMatch_NoQuestions_DescriptionSecondary',
  },
  gotPotential: {
    description:
      'Interview.HowToImproveAtInterviews.GotPotential_NoQuestions_Description',
    descriptionSecondary: '',
  },
  notClearMatch: {
    description:
      'Interview.HowToImproveAtInterviews.NotClearMatch_NoQuestions_Description',
    descriptionSecondary:
      'Interview.HowToImproveAtInterviews.NotClearMatch_NoQuestions_DescriptionSecondary',
  },
};
