import { FC, memo, MouseEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Menu } from "react-feather";
import useBreakpoint from "use-breakpoint";
import { useLazyQuery } from "@apollo/client";
import { Button, Image } from "../elements";
import Nav from "./Nav";
import UserDropdown from "./UserDropdown";
import LanguageSelector from "./LanguageSelector";
import { BREAKPOINTS } from "../../utils/constants/breakpoints";
import HubertLogo from "../../assets/images/hubert-logo.png";
import MobileNavExpanded from "./MobileNavExpanded";
import {
	LOGIN,
	LOGIN_INVITATION_EMAIL,
	REGISTRATION,
	REGISTRATION_INVITATION_EMAIL,
} from "../../utils/constants/heapIds";
import "./Header.scss";
import { Viewer } from "../../types/viewer";
import { GET_VIEWER_INFORMATION } from "../../behavior/queries/viewer.query";
import client from "../../behavior/apolloClient";
import { setCookie } from "../../utils/cookieHandlers";
import { PageRoute } from "../../constants";

interface HeaderProps {
	type?:
		| "LoggedIn"
		| "Login"
		| "Registration"
		| "RegistrationInviteEmail"
		| "SignInInviteEmail"
		| "PublicArticle"
		| "";
	interviewRedirect?: () => void;
}

interface RightSideSectionProps {
	heapId: string;
	buttonText: string;
	onClick?: MouseEventHandler;
	to?: string;
	noLanguageSelector?: boolean;
}

const RightSideSection: FC<RightSideSectionProps> = ({
	heapId,
	buttonText,
	onClick,
	to,
	noLanguageSelector,
}) => (
	<div className="right-side-section-wrapper">
		{!noLanguageSelector ? <LanguageSelector /> : ""}
		<Button id={heapId} size="small" secondary to={to} onClick={onClick}>
			{buttonText}
		</Button>
	</div>
);

const Header: FC<HeaderProps> = ({ type, interviewRedirect }) => {
	const { t } = useTranslation();
	const [navbarExpanded, setNavbarExpanded] = useState(false);
	const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");
	const isMobile = breakpoint === "sm" || breakpoint === "md";
	const [getViewerInformation, { data }] = useLazyQuery<{ viewer: Viewer }>(
		GET_VIEWER_INFORMATION
	);

	useEffect(() => {
		if (isMobile && navbarExpanded) {
			document.body.classList.add("overflow-hidden");
		} else document.body.classList.remove("overflow-hidden");

		return () => {
			document.body.classList.remove("overflow-hidden");
		};
	}, [navbarExpanded]);

	useEffect(() => {
		if (type === "LoggedIn") {
			getViewerInformation();
		}
	}, [type]);

	const setPreferredLanguageCookie = async () => {
		const viewerLanguage = data?.viewer.language;
		if (viewerLanguage) {
			setCookie("preferred-language", viewerLanguage);
			await client.refetchQueries({
				include: "active",
			});
		}
	};

	useEffect(() => {
		if (type === "LoggedIn") {
			setPreferredLanguageCookie();
		}
	}, [data]);

	const renderComponents = () => {
		switch (type) {
			case "LoggedIn":
				return !isMobile ? (
					<>
						<Nav isMobile={isMobile} />
						<UserDropdown isMobile={isMobile} viewer={data?.viewer} />
					</>
				) : (
					<Menu
						className="menu-icon"
						onClick={() => setNavbarExpanded((prevState) => !prevState)}
					/>
				);
			case "Registration":
				return (
					<RightSideSection
						heapId={REGISTRATION.BUTTON_SIGNINTOYOURACCOUNT}
						buttonText={t("Header.ButtonText_SignInToYourAccount")}
						to="/"
					/>
				);
			case "Login":
				return (
					<RightSideSection
						heapId={LOGIN.BUTTON_CREATENEWACCOUNT}
						buttonText={t("Header.ButtonText_CreateNewAccount")}
						to={PageRoute.REGISTRATION}
					/>
				);
			case "RegistrationInviteEmail":
				return (
					<RightSideSection
						heapId={LOGIN_INVITATION_EMAIL.BUTTON_STARTINTERVIEW}
						buttonText={t("Header.ButtonText_StartInterviewWithoutSigningUp")}
						onClick={interviewRedirect}
					/>
				);
			case "SignInInviteEmail":
				return (
					<RightSideSection
						heapId={REGISTRATION_INVITATION_EMAIL.BUTTON_STARTINTERVIEW}
						buttonText={t("Header.ButtonText_StartInterviewWithoutSigningIn")}
						onClick={interviewRedirect}
					/>
				);
			case "PublicArticle":
				return (
					<RightSideSection
						heapId={REGISTRATION.BUTTON_SIGNINTOYOURACCOUNT}
						buttonText={t("Header.ButtonText_SignInToYourAccount")}
						to="/"
						noLanguageSelector
					/>
				);
			default:
				return <LanguageSelector />;
		}
	};

	return (
		<div className={classNames("header-wrapper")}>
			{type === "LoggedIn" ? (
				<Link to={PageRoute.DASHBOARD}>
					<Image className="hubert-logo" src={HubertLogo} alt="hubert-logo" />
				</Link>
			) : (
				<a href="/">
					<Image className="hubert-logo" src={HubertLogo} alt="hubert-logo" />
				</a>
			)}
			{renderComponents()}
			{type === "LoggedIn" && isMobile && navbarExpanded && (
				<MobileNavExpanded
					isMobile={isMobile}
					setNavbarExpanded={setNavbarExpanded}
				/>
			)}
		</div>
	);
};

Header.defaultProps = {
	type: "",
	interviewRedirect: () => null,
};

RightSideSection.defaultProps = {
	onClick: () => "null",
	to: "",
	noLanguageSelector: false,
};

export default memo(Header);
