import { FC } from 'react';
import { CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../elements';

interface CompletedAlertProps {}

const CompletedAlert: FC<CompletedAlertProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="interview-completed-alert-wrapper">
      <div>
        <div className="icon-wrapper">
          <CheckCircle size={40} />
        </div>
      </div>

      <div className="text-content-wrapper">
        <Heading level="h2">
          {t('InterviewCompletedPageInviteEmail.CompletedAlert_Title')}
        </Heading>
        <Heading level="h2" light>
          {t('InterviewCompletedPageInviteEmail.CompletedAlert_Subtitle')}
        </Heading>
        <Text block className="mt-4">
          {t('InterviewCompletedPageInviteEmail.CompletedAlert_Description')}
        </Text>
      </div>
    </div>
  );
};

export default CompletedAlert;
