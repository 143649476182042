import { gql } from '@apollo/client';

export const VERIFY_INVITATION = gql`
  query INVITATION($token: String) {
    invitation(token: $token) {
      ... on Invitation {
        interviewId
        applicationId
        job {
          id
          title
          company
        }
        invitee {
          displayName
          email
          providerData {
            providerId
            email
          }
        }
        interviewCompleted
      }
      ... on InvitationNotFound {
        message
      }
    }
  }
`;

export const GET_UNSUBSCRIBE_REMINDER_INFO = gql`
  query INVITATION($token: String) {
    invitation(token: $token) {
      ... on Invitation {
        unsubscribed
        job {
          title
        }
      }
    }
    ... on InvitationExpired {
      message
      expTime
    }
    ... on InvitationInvalid {
      message
    }
  }
`;
