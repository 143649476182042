export const LOGIN = {
	INPUT_EMAIL: "login-input-email",
	INPUT_PASSWORD: "login-input-password",
	BUTTON_FORGOTPASSWORD: "login-button-forgotpassword",
	BUTTON_SIGNIN: "login-button-signin",
	BUTTON_CONTINUEGOOGLE: "login-button-continuegoogle",
	BUTTON_CONTINUEFACEBOOK: "login-button-continuefacebook",
	BUTTON_CREATENEWACCOUNT: "login-button-createnewaccount",
};

export const REGISTRATION = {
	INPUT_FIRSTNAME: "registration-input-firstname",
	INPUT_LASTNAME: "registration-input-lastname",
	INPUT_EMAIL: "registration-input-email",
	INPUT_PASSWORD: "registration-input-password",
	DROPDOWN_COUNTRYCODE: "registration-dropdown-countrycode",
	INPUT_PHONENUMBER: "registration-input-phonenumber",
	INPUT_PRIVACYPOLICYCHECK: "registration-input-privacypolicycheck",
	BUTTON_SIGNUP: "registration-button-signup",
	BUTTON_SIGNUPGOOGLE: "registration-button-signupgoogle",
	BUTTON_SIGNUPFACEBOOK: "registration-button-signupfacebook",
	BUTTON_SIGNINTOYOURACCOUNT: "registration-button-signintoyouraccount",
};

export const LOGIN_INVITATION_EMAIL = {
	INPUT_EMAIL: "login-invitationemail-input-email",
	INPUT_PASSWORD: "login-invitationemail-input-password",
	BUTTON_FORGOTPASSWORD: "login-invitationemail-button-forgotpassword",
	BUTTON_SIGNIN: "login-invitationemail-button-signin",
	BUTTON_CONTINUEGOOGLE: "login-invitationemail-button-continuegoogle",
	BUTTON_CONTINUEFACEBOOK: "login-invitationemail-button-continuefacebook",
	BUTTON_STARTINTERVIEW: "login-invitationemail-button-startinterview",
};

export const LOGIN_POST_RTS = {
	INPUT_EMAIL: "login-post-rts-input-email",
	INPUT_PASSWORD: "login-post-rts-input-password",
	BUTTON_FORGOTPASSWORD: "login-post-rts-button-forgotpassword",
	BUTTON_SIGNIN: "login-post-rts-button-signin",
	BUTTON_CONTINUEGOOGLE: "login-post-rts-button-continuegoogle",
	BUTTON_CONTINUEFACEBOOK: "login-post-rts-button-continuefacebook",
	BUTTON_STARTINTERVIEW: "login-post-rts-button-startinterview",
};

export const REGISTRATION_INVITATION_EMAIL = {
	INPUT_EMAIL: "registration-invitationemail-input-email",
	INPUT_PASSWORD: "registration-invitationemail-input-password",
	BUTTON_SIGNUP: "registration-invitationemail-button-signup",
	BUTTON_SIGNUPGOOGLE: "registration-invitationemail-button-signupgoogle",
	BUTTON_SIGNUPFACEBOOK: "registration-invitationemail-button-signupfacebook",
	BUTTON_STARTINTERVIEW: "registration-invitationemail-button-startinterview",
};

export const LOGIN_INTERVIEW_COMPLETED = {
	INPUT_EMAIL: "login-interviewcompleted-input-email",
	INPUT_PASSWORD: "login-interviewcompleted-input-password",
	BUTTON_FORGOTPASSWORD: "login-interviewcompleted-button-forgotpassword",
	BUTTON_SIGNIN: "login-interviewcompleted-button-signin",
	BUTTON_CONTINUEGOOGLE: "login-interviewcompleted-button-continuegoogle",
	BUTTON_CONTINUEFACEBOOK: "login-interviewcompleted-button-continuefacebook",
};

export const REGISTRATION_INTERVIEW_COMPLETED = {
	INPUT_EMAIL: "registration-interviewcompleted-input-email",
	INPUT_PASSWORD: "registration-interviewcompleted-input-password",
	BUTTON_SIGNUP: "registration-interviewcompleted-button-signup",
	BUTTON_SIGNUPGOOGLE: "registration-interviewcompleted-button-signupgoogle",
	BUTTON_SIGNUPFACEBOOK:
		"registration-interviewcompleted-button-signupfacebook",
};
