import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageRoute } from '../../constants';

interface NavProps {
  isMobile: boolean;
}

const Nav: FC<NavProps> = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <nav className={!isMobile ? 'navbar-desktop' : 'navbar-mobile'}>
      <NavLink to={PageRoute.DASHBOARD}>{t('Header.Navlink_Dashboard')}</NavLink>
      <NavLink to={PageRoute.INTERVIEWS}>
        {t('Header.Navlink_MyInterviews')}
      </NavLink>
      <NavLink to={PageRoute.LEARN_WITH_HUBERT}>
        {t('Header.Navlink_LearnWithHubert')}
      </NavLink>
      <NavLink to={PageRoute.HELP_CENTER}>{t('Header.Navlink_HelpCenter')}</NavLink>
    </nav>
  );
};

export default memo(Nav);
