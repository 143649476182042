import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { VIEWER_NOTIFICATION_UPDATE } from '../../behavior/mutations/viewer.mutation';
import { HorizontalLine, Text } from '../elements';
import SkeletonLoader from '../elements/SkeletonLoader';

interface NotificationPreferencesProps {
  loading: boolean
  notification?: number
}

const NotificationPreferences: FC<NotificationPreferencesProps> = ({ loading, notification }) => {
  const { t } = useTranslation();
  const [isToggled, setIsToggled] = useState(notification === 1);

  const [updateUserNotification] = useMutation(VIEWER_NOTIFICATION_UPDATE);

  const updateNotification = async (value: boolean) => {
    await updateUserNotification({
      variables: {
        notification: value ? 1 : 0,
      },
    });
  };

  const debounceFn = useMemo(() => debounce(
    value => updateNotification(value),
    1500,
    { trailing: true, leading: true },
  ), []);

  const setNotification = () => {
    debounceFn(!isToggled);
    setIsToggled(prevState => !prevState);
  };

  useEffect(() => {
    if (notification === 1) {
      setIsToggled(true);
    } else { setIsToggled(false); }
  }, [notification]);

  return (
    <div className="notification-preferences-wrapper">
      <Text size="large" weight="semibold">
        {t('MyProfile.NotificationPreferences.Title')}
      </Text>
      <HorizontalLine weight="semi-dark" />
      {loading ? <SkeletonLoader height="40%" /> : (
        <div
          className="toggler-wrapper"
          onClick={setNotification}
          onKeyDown={setNotification}
          role="button"
          tabIndex={0}
        >
          <div
            className={classNames(
              'toggle-pill-container',
              isToggled && 'toggled',
            )}
          >
            <div className="toggle-pill" />
          </div>
          <Text>
            {t('MyProfile.NotificationPreferences.Label_ReceiveUpdatesToggle')}
          </Text>
        </div>
      )}
      <Text size="small" block className="toggle-help-text" loading={loading}>
        {t(
          'MyProfile.NotificationPreferences.Label_ReceiveUpdatesToggleHelpText',
        )}
      </Text>
    </div>
  );
};

NotificationPreferences.defaultProps = {
  notification: 0,
};

export default NotificationPreferences;
