import { ApolloProvider } from "@apollo/client";
import {
	LEVEL_CRITICAL,
	ErrorBoundary as RollbarErrorBoundary,
	Provider as RollbarProvider,
} from "@rollbar/react";
import App from "App";
import CriticalErrorPage from "pages/ErrorPage/CriticalErrorPage";
import RollbarErrorPage from "pages/ErrorPage/RollbarErrorPage";
import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import InitializeHeap from "utils/InitializeHeap";
import client from "./behavior/apolloClient";
import { store } from "./behavior/store";
import "./i18n";
import "./styles/main.scss";
import { rollbarConfig } from "./utils/rollbarConfig";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);

const Err = () => {
	console.info({
		env: process.env.NODE_ENV,
	});
	throw new Error('test');
	return <h1>Hello.</h1>
}

class BetterRollbarErrorBoundary extends RollbarErrorBoundary {
	componentDidCatch(error: Error & { rollbarContext: any}, info: any) {
		const { message, name, stack, ...rest } = error;
		error.rollbarContext = {
			name, 
			...rest,
		}
		if(super.componentDidCatch)
			super.componentDidCatch(error, info);
	}
}

root.render(
	<React.StrictMode>
		<ErrorBoundary FallbackComponent={CriticalErrorPage}>
			<RollbarProvider config={rollbarConfig}>
				<BetterRollbarErrorBoundary
					level={LEVEL_CRITICAL}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					fallbackUI={RollbarErrorPage}
				>
					<ApolloProvider client={client}>
						<ReduxProvider store={store}>
							<InitializeHeap />
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</ReduxProvider>
					</ApolloProvider>
				</BetterRollbarErrorBoundary>
			</RollbarProvider>
		</ErrorBoundary>
	</React.StrictMode>
);
