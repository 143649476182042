import date from "date-and-time";
import jwt_decode from "jwt-decode";
import { JwtModel, TokenModel } from "types/authentication";
import { UnProtectedOperations } from "../../constants";

export const getTokens = () => ({
	accessToken: localStorage.getItem('candidate-hub-token'),
	refreshToken: localStorage.getItem('candidate-hub-refresh-token'),
})

export const getAccessToken = () => getTokens().accessToken;

export const getCurrentUser = () => {
	const token = getAccessToken();
	if(token == null) return null;
	const decoded = jwt_decode<JwtModel>(token);
	return { email: decoded.email };
}

export const isLogged = () => {
	const currentToken = getAccessToken();
	if(currentToken === null)
		return false;
	const decodedToken = jwt_decode<JwtModel>(currentToken);
	const now = new Date();
	const expireTime = Math.floor(now.getTime() / 1000.0);
	return decodedToken.exp > expireTime;
}

export const storeTokens = (data?: TokenModel) => {
  if(data == null) {
    localStorage.removeItem('candidate-hub-token');
    localStorage.removeItem('candidate-hub-refresh-token')
  } else {
    localStorage.setItem('candidate-hub-token', data.access_token);
    localStorage.setItem('candidate-hub-refresh-token', data.refresh_token);
  }
}

const clearTokens = () => storeTokens();

const isProtected = (operationName: string | undefined): boolean => {
	if (operationName?.startsWith('_') === true)
		return false;

	let protectedOperation = true;
	switch (operationName) {
		case UnProtectedOperations.SIGNIN_EMAIL_PASSWORD:
		case UnProtectedOperations.SIGNUP_EMAIL_PASSWORD:
		case UnProtectedOperations.SIGNUP_EMAIL_TOKEN:
		case UnProtectedOperations.SIGNUP_EMAIL_RTS_TOKEN:
		case UnProtectedOperations.SIGNIN_FEDERATED_LOGIN:
		case UnProtectedOperations.VERIFY_EMAIL:
		case UnProtectedOperations.REFRESH_TOKEN:
		case UnProtectedOperations.INVITATION:
		case UnProtectedOperations.EXISTING_EMAIL:
		case UnProtectedOperations.RESET_PASSWORD:
		case UnProtectedOperations.SET_PASSWORD_BY_CODE:
		case UnProtectedOperations.SEND_BENEFITS_EMAIL:
		case UnProtectedOperations.UNSUBSCRIBE_REMINDER:
		case UnProtectedOperations.OPENED_DASHBOARD:
		case UnProtectedOperations.EXCHANGE_IMPERSONATE_TOKEN:
			protectedOperation = false;
			break;
		default:
			break;
	}
	return protectedOperation;
};

const isTokenExpiring = (accessToken: string) => {
	const decodedToken = jwt_decode<JwtModel>(accessToken);
	const skewTime = date.addMinutes(new Date(), 5);
	const expireTime = Math.floor(skewTime.getTime() / 1000.0);
	if (decodedToken.exp <= expireTime) {
		return true;
	}
	return false;
};

export { clearTokens as clearTokenFromStorage, isProtected, isTokenExpiring };
