import { useLazyQuery, useMutation } from "@apollo/client";
import { SEND_BENEFITS_EMAIL } from "behavior/mutations/invitation.mutation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { VERIFY_INVITATION } from "../../../behavior/queries/invitation.query";
import Header from "../../../components/header/Header";
import CompletedAlert from "../../../components/interview/CompletedAlert";
import { PageState, TPageState } from "../../../constants";
import { Invitation } from "../../../types/invitation";
import { LOGIN_INTERVIEW_COMPLETED } from "../../../utils/constants/heapIds";
import InviteEmailSignIn from "./InviteEmailSignIn";
import "./LoginInviteEmail.scss";

const LoginInviteEmailInterviewCompleted = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [verifyInvitation] = useLazyQuery(VERIFY_INVITATION);
	const [sendBenefitsEmail] = useMutation(SEND_BENEFITS_EMAIL);
	const [invitation, setInvitation] = useState<Invitation>();
	const [pageState, setPageState] = useState<TPageState>(
		PageState.INVITATION_POST_INTERVIEW_SIGN_IN
	);
	const [searchParams] = useSearchParams();
	const hasEmail = useMemo(() => searchParams.has("email"), [searchParams]);

	const verify = useCallback(
		async (token: string): Promise<void> => {
			try {
				const response = await verifyInvitation({
					variables: {
						token,
					},
					fetchPolicy: "no-cache",
				});

				if (!response) {
					toast.error(t("AppMessages.server-error"));
					return;
				}

				const type = response.data.invitation.__typename;
				if (type !== "Invitation") {
					toast.error(t(`ProcessInvitation.${type}`));
					navigate("/", { replace: true });
					return;
				}

				const invitationData = response.data.invitation as Invitation;
				if (invitationData.interviewCompleted) {
					await sendBenefitsEmail({
						variables: {
							token,
						},
					});
					setInvitation(invitationData);
				} else {
					toast.error(
						t("InterviewCompletedPageInviteEmail.InterviewNotCompleted")
					);
					navigate("/", { replace: true });
				}
			} catch (e: any) {
				toast.error(t(e.message));
			}
		},
		[navigate, sendBenefitsEmail, t, verifyInvitation]
	);

	useEffect(() => {
		const stateParam = searchParams.get("state");
		if (stateParam) {
			const state = stateParam as TPageState;
			setPageState(state);
		}

		const token = searchParams.get("token");
		if (token) {
			verify(token);
		} else {
			if (!hasEmail) {
				navigate("/", { replace: true });
			}
		}
	}, [hasEmail, navigate, searchParams, verify]);

	return (
		<>
			<Header />
			<div className="login-interview-completed-invite-email-wrapper">
				<CompletedAlert />
				<InviteEmailSignIn
					heapIds={LOGIN_INTERVIEW_COMPLETED}
					invitation={invitation}
					state={pageState}
				/>
			</div>
		</>
	);
};

export default LoginInviteEmailInterviewCompleted;
