import { FC } from 'react';
import classNames from 'classnames';
import SkeletonLoader from './SkeletonLoader';

interface TextProps {
  size?: 'small' | 'large' | '';
  weight?: 'regular' | 'medium' | 'semibold' | 'light';
  block?: boolean;
  className?: string;
  children?: string;
  loading?: boolean;
  html?: boolean;
  onClick?: () => void;
}

const Text: FC<TextProps> = ({
  size,
  weight,
  block,
  className,
  children,
  loading,
  html,
  onClick }) => {
  const Tag = block ? 'div' : 'span';
  const textClass = size ? `body-${size}-${weight}` : `body-${weight}`;
  const skeletonClass = loading ? 'skeleton-loading' : '';

  if (!html) {
    return (
      <Tag className={classNames(textClass, className, skeletonClass)} onClick={onClick}>
        { loading ? <SkeletonLoader count={2} /> : children}
      </Tag>
    );
  }

  return loading ? (
    <Tag className={classNames(textClass, className, skeletonClass)}>
      { loading ? <SkeletonLoader count={3} /> : children }
    </Tag>
  ) : (
    <Tag className={classNames(textClass, className, skeletonClass)} dangerouslySetInnerHTML={{ __html: children ?? '' }} />
  );
};

Text.defaultProps = {
  size: '',
  weight: 'regular',
  block: false,
  className: '',
  children: '',
  loading: false,
  html: false,
  onClick: () => null,
};

export default Text;
