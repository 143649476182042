import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, HorizontalLine, Text } from "../elements";
import { Dropdown, Textbox } from "../forms";
import { yourDetailsSchema } from "../../utils/validationSchemas";
import { getCountryCodes } from "../registration/getCountryCodes";
import { Viewer } from "../../types/viewer";
import { VIEWER_PROFILE_UPDATE } from "../../behavior/mutations/viewer.mutation";

interface YourDetailsProps {
	viewer?: Viewer;
	loading: boolean;
}

const YourDetails: FC<YourDetailsProps> = ({ viewer, loading }) => {
	const { t } = useTranslation();

	const initialValues = {
		firstName: viewer?.firstName ?? "",
		lastName: viewer?.lastName ?? "",
		email: viewer?.email ?? "",
		countryCode: viewer?.countryCode ?? "+46",
		phoneNumber: viewer?.phoneNumber ?? "",
	};

	const [updateUserInfo, { loading: actionLoading }] = useMutation(
		VIEWER_PROFILE_UPDATE
	);

	const {
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleBlur,
		setValues,
		isValid,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema: yourDetailsSchema,
		onSubmit: async (props) => {
			try {
				const countryCode = props.countryCode ?? "+46";
				const phoneNumber = props.phoneNumber ?? "";
				const concatenatedPhoneNumber = `${countryCode}${phoneNumber}`;

				if (phoneNumber.length > 0) {
					if (
						concatenatedPhoneNumber.length < 8 ||
						concatenatedPhoneNumber.length > 19
					) {
						setFieldError(
							"phoneNumber",
							t("YourProfile.YourDetails.Error_PhoneNumber_Invalid")
						);
						return;
					}
				}

				const data = await updateUserInfo({
					variables: {
						profileUpdateInput: {
							email: props.email,
							firstName: props.firstName,
							lastName: props.lastName,
							phoneNumber,
							countryCode,
						},
					},
				});
				const response = data.data;
				if (!response) {
					toast.error(t("AppMessages.server-error"));
					return;
				}
				if (data.errors && data.errors.length > 0) {
					toast.error(t("AppMessages.internal-server-error"));
				} else {
					toast.success(t("MyProfile.MyDetails.Success_Details_Updated"));
				}
			} catch (e: any) {
				toast.error(e.message);
			}
		},
	});

	useEffect(() => {
		if (viewer) {
			const phoneNumber = viewer.phoneNumber ?? "";
			const countryCode = viewer?.countryCode ?? "+46";
			setValues({
				firstName: viewer?.firstName ?? "",
				lastName: viewer?.lastName ?? "",
				email: viewer?.email ?? "",
				countryCode,
				phoneNumber,
			});
		}
	}, [viewer]);

	return (
		<div className="your-details-wrapper">
			<Text size="large" weight="semibold">
				{t("MyProfile.MyDetails.Title")}
			</Text>
			<HorizontalLine weight="semi-dark" />
			<form className="your-details-form" onSubmit={handleSubmit}>
				<div className="name-field-wrapper">
					<Textbox
						label={t("MyProfile.MyDetails.Label_FirstName")}
						name="firstName"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.firstName}
						error={errors.firstName}
						touched={touched.firstName}
						loading={loading}
					/>
					<Textbox
						label={t("MyProfile.MyDetails.Label_LastName")}
						name="lastName"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.lastName}
						error={errors.lastName}
						touched={touched.lastName}
						loading={loading}
					/>
				</div>
				<Textbox
					label={t("MyProfile.MyDetails.Label_Email")}
					name="email"
					handleChange={handleChange}
					handleBlur={handleBlur}
					value={values.email}
					error={errors.email}
					touched={touched.email}
					loading={loading}
					disabled
				/>
				<div className="phone-number-field-wrapper">
					<div className="country-code-field-wrapper">
						<Dropdown
							label={t("Registration.Label_PhoneNumber")}
							name="countryCode"
							options={getCountryCodes()}
							placeholder={t("Registration.Placeholder_CountryCode")}
							handleChange={handleChange}
							handleBlur={handleBlur}
							value={values.countryCode}
							error={errors.countryCode}
							touched={touched.countryCode}
							loading={loading}
						/>
					</div>
					<div className="number-field-wrapper">
						<Textbox
							name="phoneNumber"
							placeholder={t("Registration.Placeholder_PhoneNumber")}
							handleChange={handleChange}
							handleBlur={handleBlur}
							value={values.phoneNumber}
							error={errors.phoneNumber}
							touched={touched.phoneNumber}
							loading={loading}
						/>
					</div>
				</div>
				<Button
					submit
					className="mt-2"
					hidden={loading}
					disabled={actionLoading || !isValid}
				>
					{t("MyProfile.MyDetails.ButtonText_UpdateDetails")}
				</Button>
			</form>
		</div>
	);
};

YourDetails.defaultProps = {
	viewer: undefined,
};

export default YourDetails;
