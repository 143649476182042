import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { GET_SHALLOW_CONTENTS } from "../../behavior/queries/content.query";
import { ContentCollection } from "../../types/content";
import { ArticleCardList } from "../common";
import { Heading, HorizontalLine } from "../elements";

const InterviewGuides: FC = () => {
	const { t } = useTranslation();
	const { data, loading } = useQuery<{ contentCollection: ContentCollection }>(
		GET_SHALLOW_CONTENTS,
		{
			variables: {
				candidateFilterInput: {
					tags: [],
					optionalTags: ["Interview Guide"],
					pagination: {
						limit: 12,
						page: 1,
					},
				},
			},
			fetchPolicy: "no-cache",
		}
	);

	return (
		<div className="interview-guides-wrapper">
			<Heading level="h5">{t("LearnWithHubert.InterviewGuides")}</Heading>
			<HorizontalLine weight="semi-dark" />
			<ArticleCardList
				articles={data?.contentCollection.contents ?? null}
				wrapperClass="grid-items-wrapper"
				loading={loading}
			/>
		</div>
	);
};

export default InterviewGuides;
