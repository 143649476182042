import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Image } from "../elements";
import Illustration from "../../assets/images/illustration-2.png";

const BannerSection: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="banner-section">
			<Heading level="h1">{t("LearnWithHubert.BannerSection.Title")}</Heading>
			<Heading level="h4" light>
				{t("LearnWithHubert.BannerSection.Subtitle")}
			</Heading>
			<Image src={Illustration} alt="Illustration" className="illustration" />
		</div>
	);
};

export default BannerSection;
