import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-hooks-use-modal';
import { Button, Heading, HorizontalLine, Text, Image } from '../../elements';
import HowHubertScoreModal from './howHubertScoreModal/HowHubertScoreModal';
import IllustrationGotPotential from '../../../assets/images/illustration-got-potential.png';
import IllustrationGreatMatch from '../../../assets/images/illustration-great-match.png';
import IllustrationOkMatch from '../../../assets/images/illustration-ok-match.png';
import IllustrationNotClearMatch from '../../../assets/images/illustration-not-clear-match.png';

interface AssessedMatchProps {
  scoreType: 'gotPotential' | 'greatMatch' | 'okMatch' | 'notClearMatch';
  loading?: boolean;
}

const scoreTypes = {
  gotPotential: {
    illustration: IllustrationGotPotential,
    scoreTitle: 'Interview.MyResult.GotPotential_Title',
    description: 'Interview.MyResult.GotPotential_Description',
    descriptionSecondary:
      'Interview.MyResult.GotPotential_DescriptionSecondary',
  },
  greatMatch: {
    illustration: IllustrationGreatMatch,
    scoreTitle: 'Interview.MyResult.GreatMatch_Title',
    description: 'Interview.MyResult.GreatMatch_Description',
    descriptionSecondary: '',
  },
  okMatch: {
    illustration: IllustrationOkMatch,
    scoreTitle: 'Interview.MyResult.OkMatch_Title',
    description: 'Interview.MyResult.OkMatch_Description',
    descriptionSecondary: '',
  },
  notClearMatch: {
    illustration: IllustrationNotClearMatch,
    scoreTitle: 'Interview.MyResult.NotClearMatch_Title',
    description: 'Interview.MyResult.NotClearMatch_Description',
    descriptionSecondary: '',
  },
};

const AssessedMatch: FC<AssessedMatchProps> = ({ scoreType, loading }) => {
  const { t } = useTranslation();
  const [ModalProvider, open, close, isOpen] = useModal('root', {
    closeOnOverlayClick: true,
  });

  return (
    <div className="assessed-match-wrapper">
      <Image
        src={scoreTypes[scoreType].illustration}
        alt="Assessed match illustration"
        className="illustration"
        loading={loading}
        circle
      />
      <div className="content">
        <div className="assessed-match-text-wrapper">
          <Text size="large" weight="semibold">
            {t('Interview.MyResult.Subtitle')}
          </Text>
          <HorizontalLine weight="semi-dark" />
        </div>
        <Heading level="h2" className="my-4" loading={loading}>
          {t(scoreTypes[scoreType].scoreTitle)}
        </Heading>
        <Text block loading={loading}>
          {t(scoreTypes[scoreType].description)}
        </Text>
        {scoreTypes[scoreType].descriptionSecondary && (
          <Text block className="mt-3">
            {t(scoreTypes[scoreType].descriptionSecondary)}
          </Text>
        )}
        <div className="button-wrapper">
          <Button className="mt-8 mobile-text-sm" onClick={open}>
            {t('Interview.MyResult.ButtonText_LearnMore')}
          </Button>
        </div>
      </div>
      <ModalProvider>
        <HowHubertScoreModal isOpen={isOpen} close={close} />
      </ModalProvider>
    </div>
  );
};

AssessedMatch.defaultProps = {
  loading: false,
};

export default AssessedMatch;
