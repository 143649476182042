import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Illustration from "../../assets/images/illustration-3.png";
import { GET_VIEWER_TODO_INTERVIEW } from "../../behavior/queries/application.query";
import { Viewer } from "../../types/viewer";
import { NoOngoingInterviews, ToDoInterview } from "../dashboard";
import { Heading, HorizontalLine, Image, Text } from "../elements";

const YourInterviewsSection: FC = () => {
	const { t } = useTranslation();
	const { data, loading } = useQuery<{ viewer: Viewer }>(
		GET_VIEWER_TODO_INTERVIEW
	);

	const renderToDoInterview = () => {
		const todoInterview = data?.viewer.dashboardSummary?.todoInterview;

		if (!todoInterview && !loading) {
			return <NoOngoingInterviews />;
		}

		return (
			<ToDoInterview
				job={todoInterview?.job}
				createdAt={todoInterview?.createdAt}
				interviewId={todoInterview?.interview?.id}
				applicationId={todoInterview?.id}
				loading={loading}
			/>
		);
	};

	return (
		<div className="your-interviews-section-wrapper">
			<Heading level="h1">
				{t("MyInterviews.MyInterviewsSection_Title")}
			</Heading>
			<Heading level="h4" light>
				{t("MyInterviews.MyInterviewsSection_Subtitle")}
			</Heading>
			<Image
				src={Illustration}
				alt="Interview llustration"
				className="illustration"
			/>
			<div className="to-do-section-wrapper">
				<div className="to-do-section">
					<Text size="large" weight="semibold">
						{t("MyInterviews.MyInterviewsSection_ToDo")}
					</Text>
					<HorizontalLine weight="regular" />
					{renderToDoInterview()}
				</div>
			</div>
		</div>
	);
};

export default YourInterviewsSection;
