import demoImage1 from "assets/images/demo-image-1.jpg";
import demoImage2 from "assets/images/demo-image-2.jpg";
import demoImageVolvoCars from "assets/images/demo-volvocars.jpeg";

export interface ContentItem {
	type: string;
	header: string;
	text: string[];
}
export interface DemoPage {
	id: string;
	title: string;
	location: string;
	mode: string;
	type: string;
	salary: string;
	about?: string | string[];
	content?: ContentItem[];
	qualifications: string[];
	vivatechDescription: string[];
	coverImage: string;
	registrationSteps: string[];
}

const interviewLinkDomain =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_INTERVIEW_URL
		: "http://localhost:4000";

export const getPreInterviewLink = (jobId: string | undefined) => {
	if (jobId) {
		const url = new URL(`${interviewLinkDomain}/rts/${jobId}/pre-interview`);
		return url.href;
	}
	return "/";
};

const demoPages: DemoPage[] = [
	{
		id: "1",
		title: "Customer Support Agent",
		location: "Miami, Florida",
		mode: "Hybrid",
		type: "Full Time",
		salary: "$ 35,000 - 45,000 / year",
		coverImage: demoImage1,
		about: [
			"Join our great team at “Alfa Inc” as a Customer Support Agent (full-time position). We are looking for motivated and skilled professionals to join our team and help us take care of our customers and grow our business",
		],
		registrationSteps: [
			"Don't send any CVs or cover letters to us. Simply do an interview with our virtual AI assistant Hubert.",
			"Apply now by clicking on the purple button and follow the instructions",
		],
		vivatechDescription: [
			"Get the candidate experience of what it can be like to do a Hubert interview now!",
			"After the short mock interview, you are asked to sign into My Hubert. Then you can re-use your answers from this interview in a second interview with Hubert, and enjoy a really fast and smooth candidate experience. Also, YOU have a chance to win a great prize! ",
		],
		qualifications: [
			"Bachelor's degree in Business Administration, Logistics, or a related field",
			"Minimum 2 years of experience in a similar role",
			"Excellent communication skills",
		],
	},
	{
		id: "2",
		title: "Customer Support Agent (Part Time)",
		location: "Paris, France",
		mode: "Hybrid",
		type: "Part Time",
		salary: "€ 13-17 / hour",
		coverImage: demoImage2,
		vivatechDescription: [
			"Remember to re-use your answers from your first interview. It will make this second interview really fast! Will you win the great prize? Click on Apply now!",
		],
		about: [
			"Join our great team at “Alfa Inc” as a Customer Support Agent (part-time position). We are looking for motivated and skilled professionals to join our team and help us take care of our customers and grow our business.",
		],
		registrationSteps: [
			"Don't send any CVs or cover letters to us. Simply do an interview with our virtual AI assistant Hubert.",
			"Apply now by clicking on the purple button and follow the instructions.",
		],
		qualifications: [
			"Bachelor's degree in Business Administration, Logistics, or a related field",
			"Minimum 2 years of experience in a similar role",
			"Excellent communication skills",
		],
	},
	{
		id: "3",
		title: "Mekaniker till VCBC Underhåll Södra fabriken",
		location: "Olofström",
		mode: "Sverige",
		type: "Heltid",
		salary: "Nattskift",
		coverImage: demoImageVolvoCars,
		content: [
			{
				type: "article",
				header: "Vill du utveckla tekniken för att göra livet enklare?",
				text: [
					"På Volvo Cars har vi i uppdrag att förenkla människors liv. Istället för att starta med utvecklingen av tekniken och få människorna att anpassa sig till den, så designar vi våra bilar och dess tekniska lösningar kring och för människorna istället. Vi skapar för att kunna ge alla människor en säker, hållbar och bekväm mobilitet. Vill du bli en del av oss?"
				]
			},
			{
				type: "article",
				header: "Vad erbjuder vi",
				text: [
					"Underhåll på Södra fabriken arbetar med förebyggande underhåll för olika typer av maskiner och kringutrustning. Vi gör även akuta avhjälpande underhållsåtgärder under produktion. Vi värdesätter mångfald då vi anser att detta bidrar till kreativitet, utveckling och bred expertis. Vi söker nu en mekaniker till vårt nattskift."
				]
			},
			{
				type: "article",
				header: "Vad kommer du att utföra?",
				text: [
					"Som mekaniker hos oss kommer du främst arbeta med att utföra förebyggande samt förbättrande underhållsåtgärder enligt arbetsorder, men även utföra akuta avhjälpande underhållsåtgärder under produktion.",
					"Du kommer även att medverka vid haverianalyser, arbeta med ständiga förbättringar (DMAIC) samt bidra till lagets utveckling genom målstyrt lagarbete."
				]
			},
			{
				type: "article",
				header: "Du och dina färdigheter",
				text: [
					"Du har ett stort intresse av att jobba med felsökning och att underhålla olika typer av maskiner och installationer. Vi tror att du har en minst 3-årig gymnasieutbildning / mekaniker utbildning eller motsvarande.",
					"Då vårt uppdrag är i en komplex miljö med olika utmaningar ser vi positivt på om du har erfarenheter från till exempel automation eller andra förekommande arbetsuppgifter i en produktionsmiljö.",
					"Vi tror att du också har goda kunskaper i såväl svenska som engelska, tal och skrift, samt att du med lätthet kan använda datorer, digitala verktyg, Microsoft Office program och liknande."
				]
			},
			{
				type: "article",
				header: "Vem är du?",
				text: [
					"Du gillar teknik och har alltid höga krav på att leverera med hög kvalitét från början i allt du tar dig för. Som person är du social och har lätt för att bygga goda relationer så att samarbeta med andra är lätt och ger dig energi. Du är lyhörd, service inriktad, anpassar din kommunikation efter mottagare och bemöter alla på ett professionellt sätt.",
					"Du trivs med att ha ett arbete som är händelsestyrt, är van att prioritera om och anpassar dig lätt till olika situationer som uppstår. Du är öppen för förändringar och du har förmåga att se på saker med nya infallsvinklar.",
					"Vidare så trivs du med att ha struktur och där andra ser problem, ser du en utmaning att lösa. Personligen tar du gärna initiativ till att utveckla dig själv, lära dig nya saker och du delar gärna med dig av kunskaperna till personer i din omgivning"
				]
			},
		],
		registrationSteps: [
			"Gör en jobbintervju direkt med vår virtuella AI-assistent Hubert.",
			"Ansök nu genom att klicka på den lila knappen och följ instruktionerna där.",
		],
		vivatechDescription: [],
		qualifications: [],
	},
	{
		id: "4",
		title: " Mekaniker/Mechanic ",
		location: " Olofström, Sweden ",
		mode: "On Site",
		type: "Full Time",
		salary: "Daytime Shift",
		coverImage: demoImageVolvoCars,
		about: [
			"At Volvo Cars, we have a mission to simplify people's lives. Instead of starting with the development of the technology and getting people to adapt to it, we design our cars and their technical solutions for people. We create to be able to provide all people with safe, sustainable and comfortable mobility. Do you want to join us?",
		],
		registrationSteps: [
			"Simply do an interview with our virtual AI assistant Hubert.",
			"Apply now by clicking on the purple button and follow the instructions.",
		],
		vivatechDescription: [],
		qualifications: [],
	},
	{
		id: "",
		title: "",
		location: "",
		mode: "",
		type: "",
		salary: "",
		coverImage: demoImage1,
		vivatechDescription: [
			"",
		],
		about: [
			"",
		],
		registrationSteps: [
			"",
		],
		qualifications: [
			"",
		],
	},
];

export default demoPages;
