import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '../../../assets/images/illustration-1.png';
import Application from '../../../types/application';
import { Heading, HorizontalLine, Image, Text } from '../../elements';
import MostRecentInterview from './MostRecentInterview';
import NoRecentInterviews from './NoRecentInterviews';
import ToDoInterview from './ToDoInterview';

interface YourCareerProps {
  recentInterview: Application | undefined;
  todoInterview: Application | undefined;
  loading: boolean;
}

const YourCareer: FC<YourCareerProps> = ({
  recentInterview,
  todoInterview,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="your-career-wrapper">
      <Heading level="h1">{t('Dashboard.MyCareer.Title')}</Heading>
      <Heading level="h4" light>
        {t('Dashboard.MyCareer.Subtitle')}
      </Heading>
      <Image src={Illustration} alt="Illustration" className="illustration" />
      <div className="interview-status-section">
        <div className="section">
          <Text size="large" weight="semibold">
            {t('Dashboard.MyCareer.MostRecentInterview')}
          </Text>
          <HorizontalLine weight="regular" />
          { recentInterview === undefined && !loading ? (
            <NoRecentInterviews />
          ) : (
            <MostRecentInterview
              recentInterview={recentInterview}
              loading={loading}
            />
          )}
        </div>
        { todoInterview !== undefined && !loading && (
        <div className="section">
          <Text size="large" weight="semibold">
            {t('Dashboard.MyCareer.ToDo')}
          </Text>
          <HorizontalLine weight="regular" />
          <ToDoInterview
            job={todoInterview?.job}
            createdAt={todoInterview?.createdAt}
            interviewId={todoInterview?.interview?.id}
            applicationId={todoInterview?.id}
            loading={loading}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default YourCareer;
