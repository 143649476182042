import TransitionPage from 'pages/transition/TransitionPage';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalLine, Modal, Text } from '../../elements';

interface IframeModalProps {
  isOpen: boolean;
  close: () => void;
  interviewId: string,
  isLegacyInterview: boolean,
}

const IframeModal: FC<IframeModalProps> = ({
  isOpen, close, interviewId,
  isLegacyInterview = false }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const src = isLegacyInterview ? 
  `${process.env.REACT_APP_OLD_INTERVIEW_URL}/viewer?interview_id=${interviewId}`:
  `${process.env.REACT_APP_INTERVIEW_URL}/preview/${interviewId}`;

  console.info('IframeModal', { interviewId, src });

  return (
    <Modal isOpen={isOpen} close={close} className="iframe-modal-popup" large>
      <div>
        <Text size="large" weight="semibold">
          {t('Interview.ScriptFromInterview.Title')}
        </Text>
        <HorizontalLine weight="semi-dark" />
      </div>
      {
        loading ? (
          <TransitionPage 
            title={t('Interview.ScriptFromInterview.Loading_Title')}
            subtitle={t('Interview.ScriptFromInterview.Loading_Subtitle')} />
        ) : (
          <div />
        )
      }
      <iframe
        src={src}  
        title="Interview script"
        onLoad={() => setLoading(false)}
      />
    </Modal>
  );
};

export default IframeModal;
