import * as Yup from 'yup';
import { REASONS } from '../../components/unsubscribeFromEmails/constants';

const unsubscribeFormSchema = Yup.object().shape({
  yourReason: Yup.string()
    .when('reason', {
      is: REASONS.OTHER,
      then: Yup.string().required('UnsubscribeFromEmails.Error_YourReason_Required'),
    }),
});

export default unsubscribeFormSchema;
