import { gql } from "@apollo/client";

export const EXISTING_EMAIL = gql`
  query EXISTING_EMAIL($email: String!) {
    isEmailUsed(email: $email) {
        payload
        errorMessage
        code
        isError
    }
  }
`;