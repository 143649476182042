import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import Header from "../../components/header/Header";
import InviteEmailSignUp from "./InviteEmailSignUp";
import { Invitation, InvitationTokenPayload } from "../../types/invitation";
import { REGISTRATION_INVITATION_EMAIL } from "../../utils/constants/heapIds";
import "./RegistrationInviteEmail.scss";
import { PageState } from "../../constants";

const RegistrationInviteEmail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [invitation, setInvitation] = useState<Invitation | null>();
	const params = new URLSearchParams(location.search);

	useEffect(() => {
		if (location.state) {
			const details = location.state as { invitation: Invitation };
			setInvitation(details.invitation);
		} else {
			navigate("/", { replace: true });
		}
	}, []);

	const getInterviewLink = () => {
		const tokenValue = params.get("token");
		if (tokenValue) {
			const jwt = jwt_decode<InvitationTokenPayload>(tokenValue);
			const redirectUrl = encodeURIComponent(
				`${window.location.origin}/interview-completed-invite-email?email=${jwt.email}` +
					`&token=${tokenValue}&state=${PageState.SIGN_UP}`
			);
			const interviewUrl = process.env.REACT_APP_INTERVIEW_URL;
			return `${interviewUrl}/${jwt.interviewId}?redirectUrl=${redirectUrl}`;
		}
		return "/";
	};

	const redirectToPreInterview = () => {
		if (invitation) {
			navigate("/pre-interview", {
				state: { interviewUrl: getInterviewLink(), invitation },
			});
		} else {
			toast.info("Invalid Invitation.");
		}
	};

	return (
		<>
			<Header
				type="RegistrationInviteEmail"
				interviewRedirect={redirectToPreInterview}
			/>
			<InviteEmailSignUp
				invitation={invitation}
				state={PageState.INTERVIEW_COMPLETED}
				heapIds={REGISTRATION_INVITATION_EMAIL}
			/>
		</>
	);
};

export default RegistrationInviteEmail;
