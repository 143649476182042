import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../elements';
import { ReactComponent as HubertLogo } from '../../assets/images/hubert-logo-mini.svg';

interface StillHaveQuestionProps {}

const StillHaveQuestion: FC<StillHaveQuestionProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="still-have-question-wrapper">
      <HubertLogo className="hubert-logo" />
      <Heading level="h4" className="mt-5 mb-2">
        {t('HelpCenter.StillHaveQuestion.Title')}
      </Heading>
      <Text block className="mb-1.5">
        {t('HelpCenter.StillHaveQuestion.Description')}
      </Text>
      <Text weight="light" block>{t('HelpCenter.StillHaveQuestion.DropUsAEmail')}</Text>
    </div>
  );
};

export default StillHaveQuestion;
