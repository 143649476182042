import ErrorPage from "..";

interface RollbarErrorPageProps {
	error: string;
	resetError: any;
}

const RollbarErrorPage = ({ error }: RollbarErrorPageProps) => {
	return (
		<ErrorPage
			title="We encountered an issue"
			description="We're sorry for the inconvenience. We've reported this to our analytics. In the meantime please try contacting support."
			hideNextSteps={false}
		/>
	);
};

export default RollbarErrorPage;
