import { useMutation } from "@apollo/client";
import TransitionPage from "pages/transition/TransitionPage";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { VerifyEmailResponse } from "../behavior/graphTypes/authenticationTypes";
import { VERIFY_EMAIL } from "../behavior/mutations/auth.mutation";
import { PageRoute, PageState } from "../constants";

const EmailVerification = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const shouldExecute = useRef(true);
	const navigate = useNavigate();
	const params = new URLSearchParams(location.search);
	const [verifyEmail] = useMutation<VerifyEmailResponse>(VERIFY_EMAIL);

	const verifyCode = async (
		code: string,
		redirectUrl: string,
		email: string
	): Promise<void> => {
		try {
			const data = await verifyEmail({
				variables: { code, email },
			});

			const response = data.data?.verifyEmail;
			if (!response) {
				toast.error(t("AppMessages.server-error"));
				return;
			}
			const encodedEmail = encodeURIComponent(email);
			if (response.isError && response.errorMessage) {
				toast.error(t(`AppMessages.${response.errorMessage}`));
				if (response.errorMessage === PageState.EMAIL_PREVIOUSLY_VERIFIED) {
					navigate(
						`/?state=${PageState.EMAIL_PREVIOUSLY_VERIFIED}&email=${encodedEmail}`,
						{ replace: true }
					);
					return;
				}
				navigate(PageRoute.ROOT, { replace: true });
			} else {
				navigate(`/?state=${PageState.VERIFIED_EMAIL}&email=${encodedEmail}`, {
					replace: true,
				});
			}
		} catch (e: any) {
			toast.error(t(e.message));
		}
	};

	useEffect(() => {
		if (!shouldExecute.current) {
			return;
		}
		shouldExecute.current = false;

		const mode = params.get("mode");
		const code = params.get("oobCode");
		const redirectUrlString = params.get("continueUrl");
		const email = params.get("email");

		if (mode === "verifyEmail" && code && redirectUrlString && email) {
			verifyCode(code, redirectUrlString, email);
		} else {
			navigate(PageRoute.ROOT, { replace: true });
		}
	}, []);

	return (
		<TransitionPage
			title={t("AppMessages.verifying-email-title")}
			subtitle={t("AppMessages.verifying-email-subtitle")}
		/>
	);
};

export default EmailVerification;
