import { ComponentProps, FC, useEffect } from "react";
import classNames from "classnames";
import { X } from "react-feather";

interface ModalProps extends ComponentProps<"div"> {
	isOpen?: boolean;
	close: () => void;
	large?: boolean;
}

const Modal: FC<ModalProps> = ({
	isOpen,
	close,
	large,
	className,
	children,
}) => {
	useEffect(() => {
		const bodyTag = document.querySelector("body");

		if (isOpen) {
			bodyTag?.classList.add("no-scroll");
		}

		return () => {
			bodyTag?.classList.remove("no-scroll");
		};
	}, [isOpen]);

	return (
		<div className={classNames("modal-wrapper", large && "large", className)}>
			<X className="close-icon" onClick={close} />
			{children}
		</div>
	);
};

Modal.defaultProps = {
	isOpen: false,
	large: false,
	className: "",
	children: "",
};

export default Modal;
