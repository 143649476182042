import countries from '../../utils/countries.json';

export const getCountryCodes = () => {
  const countryCodes = countries.map(country => parseInt(country.dial_code, 10));
  const filteredCountryCodes = countryCodes
    .filter(
      (countryCode: number, pos) => countryCodes.indexOf(countryCode) === pos,
    )
    .sort((a, b) => a - b)
    .map(countryCode => ({
      name: `+${countryCode}`,
      value: `+${countryCode}`,
    }));
  return filteredCountryCodes;
};
