import { useQuery } from '@apollo/client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GET_VIEWER_INFORMATION } from '../../../behavior/queries/viewer.query';
import { PageRoute, PageState } from '../../../constants';
import Job from '../../../types/job';
import { Viewer } from '../../../types/viewer';
import { generateLiteralDay } from '../../../utils/dateUtilities';
import { generateInterviewUrl } from '../../../utils/urlUtility';
import { StartInterviewButton } from '../../common';
import { Text } from '../../elements';
import SkeletonLoader from '../../elements/SkeletonLoader';

interface ToDoInterviewProps {
  applicationId?: string;
  interviewId?: string;
  job?: Job;
  createdAt?: Date;
  loading?: boolean;
}

const ToDoInterview: FC<ToDoInterviewProps> = ({
  applicationId,
  interviewId,
  job,
  createdAt,
  loading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useQuery<{ viewer: Viewer }>(
    GET_VIEWER_INFORMATION,
  );

  const redirectToInterview = () => {
    if (!interviewId) {
      return;
    }
    const displayName = data?.viewer.displayName ?? data?.viewer.email;
    const interviewStartUrl = generateInterviewUrl(interviewId, `${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`);
    navigate(PageRoute.PRE_INTERVIEW, {
      state: { interviewUrl: interviewStartUrl, invitation: { invitee: { displayName } } },
    });
  };

  return (
    <>
      {!loading && data && applicationId && interviewId && (
        <div className="section-body mt-5">
          <div className="left-body-section">
            <Text weight="medium" block>
              {job?.company}
            </Text>
            <Text weight="light" block className="mt-2">
              {job?.title}
            </Text>
          </div>
          <div className="right-body-section">
            <Text className="created-at" block>
              {generateLiteralDay(createdAt ?? null, t('Common.MyInterviews.ApplicationDate_Invited'), t)}
            </Text>
            <StartInterviewButton
              btnLabel={t('Dashboard.MyCareer.ButtonText_StartInterview')}
              hidden={loading && !data}
              redirectToInterview={redirectToInterview}
              applicationId={applicationId}
              interviewId={interviewId}
              oldInterviewFlow={job?.candidateApp !== true}
            />
          </div>
        </div>
      )}
      {loading && (
      <div className="col-span-full pt-3">
        <SkeletonLoader count={2} />
      </div>
      )}
    </>
  );
};

ToDoInterview.defaultProps = {
  job: {
    id: '',
    company: '',
    title: '',
    candidateApp: false,
  },
  interviewId: '',
  applicationId: '',
  createdAt: undefined,
  loading: false,
};

export default ToDoInterview;
