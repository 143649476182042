import classNames from "classnames";
import useLockBodyScroll from "helpers/hooks/useBodyScrollLock";
import { ComponentProps, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { X } from "react-feather";
import { CSSTransition } from "react-transition-group";
import "./dialog.scss";

interface DialogProps extends Omit<ComponentProps<"dialog">, "ref"> {
	closeOnBackdropClick?: boolean;
	onClose?: () => void;
	render?: (open?: boolean) => React.ReactNode;
	floatingCloseBtn?:boolean;
	closeBtnText?: string;
}

const Dialog = ({
	closeOnBackdropClick,
	onClose,
	open,
	render,
	floatingCloseBtn,
	closeBtnText,
	...dialogProps
}: DialogProps) => {
	const dialogRef = useRef<HTMLDialogElement>(null);
	useLockBodyScroll(dialogRef.current?.open ?? false);

	useEffect(() => {
		if (!closeOnBackdropClick) {
			return;
		}

		const dialogRefValue = dialogRef.current;

		const handleBackdropClick =
			(dialog?: HTMLDialogElement) => (event: MouseEvent) => {
				if (dialog) {
					const rect = dialog.getBoundingClientRect();
					const isInDialog =
						rect.top <= event.clientY &&
						event.clientY <= rect.top + rect.height &&
						rect.left <= event.clientX &&
						event.clientX <= rect.left + rect.width;
					if (!isInDialog) {
						dialog.close();
					}
				}
			};

		if (dialogRefValue) {
			dialogRefValue.addEventListener(
				"click",
				handleBackdropClick(dialogRef.current!)
			);

			return () => {
				dialogRefValue.removeEventListener(
					"click",
					handleBackdropClick(dialogRefValue!)
				);
			};
		}
	}, [closeOnBackdropClick]);

	useEffect(() => {
		if (open && !dialogRef.current?.open) {
			dialogRef.current?.showModal();
		} else {
			onClose?.();
			dialogRef.current?.close();
		}
	}, [open, onClose]);

	const handleClose = () => {
		onClose?.();
		dialogRef.current?.close();
	};

	return createPortal(
		<CSSTransition
			in={open}
			classNames="hubert-dialog-container"
			addEndListener={() => void 0}
			timeout={500}
		>
			<dialog
				open
				{...dialogProps}
				ref={dialogRef}
				className={classNames("hubert-dialog", dialogProps.className)}
			>
				<header data-floating={floatingCloseBtn}>
					<button onClick={handleClose}>
						<span>
							<X />
						</span>
						{closeBtnText && <span>{closeBtnText}</span>}
					</button>
				</header>
				{render && render(open)}
				{dialogProps.children && dialogProps.children}
			</dialog>
		</CSSTransition>,
		document.body
	);
};

export default Dialog;
