import { Dispatch, FC, SetStateAction } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Textbox, Dropdown, Password } from "../forms";
import { getCountryCodes } from "./getCountryCodes";
import { Button, Text } from "../elements";
import { registrationFormSchema } from "../../utils/validationSchemas";
import { SIGNUP_EMAIL_PASSWORD } from "../../behavior/mutations/auth.mutation";
import { User } from "../../types/user";
import { BooleanResponse } from "../../behavior/graphTypes/commonResponse";
import { LargeAlertProps } from "../elements/LargeAlert";
import { RegistrationHeapType } from "../../types/heapId";
import { getCookie } from "../../utils/cookieHandlers";
import { ApplicationLanguage } from "../../constants";
import Link from "components/elements/Link";

interface RegistrationFormProps {
	setShowAlert: Dispatch<SetStateAction<LargeAlertProps>>;
	heapIds: RegistrationHeapType;
}

const RegistrationForm: FC<RegistrationFormProps> = ({
	setShowAlert,
	heapIds,
}) => {
	const { t } = useTranslation();
	const [signUpEmailPassword, { loading }] = useMutation<
		any,
		{ registerUserInput: User }
	>(SIGNUP_EMAIL_PASSWORD);

	const {
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldError,
	} = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			countryCode: "+46",
			phoneNumber: "",
			privacyPolicyCheck: "",
		},
		validationSchema: registrationFormSchema,
		onSubmit: async (props) => {
			if (props) {
				try {
					const countryCode = props!.countryCode ?? "+46";
					const phoneNumber = props!.phoneNumber ?? "";
					const concatenatedPhoneNumber = `${countryCode}${phoneNumber}`;

					if (phoneNumber.length > 0) {
						if (
							concatenatedPhoneNumber.length < 8 ||
							concatenatedPhoneNumber.length > 19
						) {
							setFieldError(
								"phoneNumber",
								t("Registration.Error_PhoneNumber_Invalid")
							);
							return;
						}
					}

					const response = await signUpEmailPassword({
						variables: {
							registerUserInput: {
								firstName: props!.firstName,
								lastName: props!.lastName,
								phoneNumber,
								countryCode,
								email: props!.email,
								password: props!.password,
								language: getCookie("language") ?? ApplicationLanguage.EN_EU,
							},
						},
					});
					const type = response.data?.signUpUsingPassword.__typename;
					if (type === "BooleanResponse") {
						const booleanResponse = response.data
							.signUpUsingPassword as BooleanResponse;
						if (booleanResponse.payload) {
							setShowAlert({
								title: t("AppMessages.verify-email-title"),
								subtitle: t("AppMessages.verify-email-subtitle"),
								type: "info",
							});
						} else {
							if (booleanResponse.errorMessage === "email-is-taken") {
								setFieldError("email", t("Registration.Error_Email_Exists"));
								return;
							}
							toast.error(t(`AppMessages.${booleanResponse.errorMessage}`));
						}
						return;
					}
					toast.error(t("AppMessages.server-error"));
				} catch (e: any) {
					toast.error(t(e.message));
				}
			}
		},
	});

	return (
		<form className="registration-form" onSubmit={handleSubmit}>
			<div className="sm:grid sm:grid-cols-2 sm:gap-5">
				<Textbox
					id={heapIds.INPUT_FIRSTNAME}
					label={`${t("Registration.Label_FirstName")}*`}
					name="firstName"
					handleChange={handleChange}
					handleBlur={handleBlur}
					value={values.firstName}
					error={errors.firstName}
					touched={touched.firstName}
				/>
				<Textbox
					id={heapIds.INPUT_LASTNAME}
					label={`${t("Registration.Label_LastName")}*`}
					name="lastName"
					handleChange={handleChange}
					handleBlur={handleBlur}
					value={values.lastName}
					error={errors.lastName}
					touched={touched.lastName}
				/>
			</div>
			<Textbox
				id={heapIds.INPUT_EMAIL}
				label={`${t("Registration.Label_Email")}*`}
				name="email"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.email}
				error={errors.email}
				touched={touched.email}
			/>
			<Password
				id={heapIds.INPUT_PASSWORD}
				label={`${t("Registration.Label_Password")}*`}
				name="password"
				helpText={t("Registration.HelpText_Password")}
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.password}
				error={errors.password}
				touched={touched.password}
			/>
			<div className="grid grid-cols-12 gap-5">
				<div className="col-span-4 lg:col-span-5 2xl:col-span-3">
					<Dropdown
						id={heapIds.DROPDOWN_COUNTRYCODE}
						label={t("Registration.Label_PhoneNumber")}
						name="countryCode"
						options={getCountryCodes()}
						placeholder={t("Registration.Placeholder_CountryCode")}
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.countryCode}
						error={errors.countryCode}
						touched={touched.countryCode}
					/>
				</div>
				<div className="col-span-8 lg:col-span-7 2xl:col-span-9">
					<Textbox
						id={heapIds.INPUT_PHONENUMBER}
						name="phoneNumber"
						placeholder={t("Registration.Placeholder_PhoneNumber")}
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.phoneNumber}
						error={errors.phoneNumber}
						touched={touched.phoneNumber}
					/>
				</div>
			</div>
			{/* <CustomCheckbox
        id={heapIds.INPUT_PRIVACYPOLICYCHECK}
        name="privacyPolicyCheck"
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={values.privacyPolicyCheck}
        error={errors.privacyPolicyCheck}
        touched={touched.privacyPolicyCheck}
      />
      <div>
        <Text size="small">{`${t('Registration.Label_TermsOfService')} `}</Text>
        <Text size="small" weight="semibold" className="underline">
          {t('Registration.Label_TermsOfServiceLink')}
        </Text>
      </div> */}
			<div className="terms-and-condition-text-wrapper">
				<Text size="small">
					{`${t("RegistrationInviteEmail.PrivacyPolicyText")} `}
				</Text>
				<Link
					to="https://www.hubert.ai/candidate-privacy-policy"
					target="_blank"
					rel="noreferrer"
				>
					<Text size="small" weight="semibold">
						{t("RegistrationInviteEmail.PrivacyPolicyLink")}
					</Text>
					.
				</Link>
			</div>
			<Button
				id={heapIds.BUTTON_SIGNUP}
				fullWidth
				submit
				className="mt-2"
				disabled={loading}
			>
				{t("Registration.ButtonText_SignUp")}
			</Button>
		</form>
	);
};

export default RegistrationForm;
