import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-hooks-use-modal';
import { Button, HorizontalLine, Text } from '../../elements';
import IframeModal from './IframeModal';

export interface ScriptFromInterviewProps {
  interviewId: string,
  isLegacyInterview: boolean,
}

const ScriptFromInterview: FC<ScriptFromInterviewProps> = ({ interviewId, isLegacyInterview }) => {
  const { t } = useTranslation();
  const [ModalProvider, open, close, isOpen] = useModal('root', {
    closeOnOverlayClick: true,
  });

  return (
    <div className="script-from-interview-wrapper">
      <Text size="large" weight="semibold">
        {t('Interview.ScriptFromInterview.Title')}
      </Text>
      <HorizontalLine weight="semi-dark" />
      <div className="button-wrapper">
        <Button size="small" onClick={open} disabled={interviewId === ''}>
          {t('Interview.ScriptFromInterview.ButtonText_SeeWholeTranscript')}
        </Button>
        {/* <Button size="small" secondary>
          {t('Interview.ScriptFromInterview.ButtonText_DeleteInterview')}
        </Button> */}
      </div>
      <ModalProvider>
        <IframeModal
          isOpen={isOpen}
          close={close}
          interviewId={interviewId}
          isLegacyInterview={isLegacyInterview}
        />
      </ModalProvider>
    </div>
  );
};

export default ScriptFromInterview;
