import { useState } from "react";
import { useTranslation } from "react-i18next";
import MiniLogoImage from "../../assets/images/hubert-logo-mini.png";
import { Heading, Image, LargeAlert } from "../../components/elements";
import { LargeAlertProps } from "../../components/elements/LargeAlert";
import Header from "../../components/header/Header";
import BenefitsBox from "../../components/registration/BenefitsBox";
import RegistrationForm from "../../components/registration/RegistrationForm";
import SocialMediaRegistration from "../../components/registration/SocialMediaRegistration";
import { REGISTRATION } from "../../utils/constants/heapIds";
import "./Registration.scss";
import { RegistrationOrLine } from "./RegistrationOrLine";

const Registration = () => {
	const { t } = useTranslation();
	const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: "",
		subtitle: "",
		type: "info",
	});

	return (
		<>
			<Header type="Registration" />
			<div className="registration-wrapper">
				<div className="left-section">
					<Heading level="h2">{t("Registration.MainTitle")}</Heading>
					<Heading level="h4" light>
						{t("Registration.Subtitle")}
					</Heading>
					<BenefitsBox />
				</div>
				<div className="right-section">
					<Image
						className="hubert-logo-mini"
						src={MiniLogoImage}
						alt="Hubert logo mini"
					/>
					<Heading level="h4">{t("Registration.RegistrationTitle")}</Heading>
					{showAlert.title ? (
						<LargeAlert
							title={showAlert.title}
							subtitle={showAlert.subtitle}
							type={showAlert.type}
						/>
					) : (
						<div>
							<RegistrationForm
								setShowAlert={setShowAlert}
								heapIds={REGISTRATION}
							/>
							<RegistrationOrLine />
							<SocialMediaRegistration heapIds={REGISTRATION} />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Registration;
