import date from 'date-and-time';
import { TFunction } from 'i18next';

const generateLiteralDay = (
  dateValue: Date | null,
  prefix: string | null,
  t: TFunction,
): string => {
  let literalDay = '';
  if (dateValue == null) return literalDay;
  const sourceDate = new Date(dateValue);
  const currentDate = new Date();
  if (currentDate > sourceDate) {
    const minutesDiff = Math.floor(
      date.subtract(sourceDate, currentDate).toMinutes() * -1,
    );
    if (minutesDiff < 60) {
      if (minutesDiff < 2) {
        literalDay = `${minutesDiff} ${t('Common.MyInterviews.ApplicationDate_MinuteAgo')}`;
      } else {
        literalDay = `${minutesDiff} ${t('Common.MyInterviews.ApplicationDate_MinutesAgo')}`;
      }
    }
    const hoursDiff = Math.floor(minutesDiff / 60);
    if (hoursDiff > 0 && hoursDiff < 24) {
      if (hoursDiff < 2) {
        literalDay = `${hoursDiff} ${t('Common.MyInterviews.ApplicationDate_HourAgo')}`;
      } else {
        literalDay = `${hoursDiff} ${t('Common.MyInterviews.ApplicationDate_HoursAgo')}`;
      }
    }
    const days = Math.floor(hoursDiff / 24);
    if (days > 0 && days < 4) {
      if (days < 2) {
        literalDay = `${days} ${t('Common.MyInterviews.ApplicationDate_DayAgo')}`;
      } else {
        literalDay = `${days} ${t('Common.MyInterviews.ApplicationDate_DaysAgo')}`;
      }
    }
  }

  if (literalDay === '') {
    literalDay = new Intl.DateTimeFormat(
      t('locale'),
      { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' },
    ).format(sourceDate);
  }
  return prefix !== null ? `${prefix} ${literalDay}` : literalDay;
};

const getLocalDate = (dateValue: Date | null | undefined): string => {
  if (dateValue === null || dateValue === undefined) return '';
  const sourceDate = new Date(dateValue);
  return date.format(sourceDate, 'MMMM DD, YYYY');
};

export { generateLiteralDay, getLocalDate };
