import { FC } from 'react';
import useBreakpoint from 'use-breakpoint';
import classNames from 'classnames';
import { BREAKPOINTS, Breakpoint } from '../../utils/constants/breakpoints';
import ArticleCard from './ArticleCard';
import SkeletonLoader from '../elements/SkeletonLoader';
import { Content } from '../../types/content';
import { PageRoute } from '../../constants';

interface ArticleCardListProps {
  articles: Content[] | null;
  wrapperClass?: 'grid-items-wrapper' | 'article-card-wrapper';
  loading: boolean;
}

function getSkeletonCountToShow(breakpoint: Breakpoint) {
  if (breakpoint === 'sm') {
    return 2;
  }

  if (breakpoint === 'md') {
    return 2;
  }
  return 4;
}

const ArticleCardList: FC<ArticleCardListProps> = ({
  articles,
  wrapperClass,
  loading,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'lg');
  const noOfSkeletonToShow = getSkeletonCountToShow(breakpoint);

  if (loading) {
    const rows = [];
    for (let index = 0; index < noOfSkeletonToShow; index += 1) {
      const loader = (
        <SkeletonLoader
          containerClassName="custom-article-card"
          className={wrapperClass}
        />
      );
      rows.push(loader);
    }
    return <div className={classNames(wrapperClass, 'skeleton-wrapper')}>{rows}</div>;
  }

  return (
    <div className={wrapperClass}>
      {articles &&
        articles.map(article => (
          <ArticleCard
            className="custom-article-card"
            article={article}
            to={`${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${article.id}`}
            key={article.title}
          />
        ))}
    </div>
  );
};

ArticleCardList.defaultProps = {
  wrapperClass: 'article-card-wrapper',
};

export default ArticleCardList;
