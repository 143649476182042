import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Heading, HorizontalLine, Text, Button } from '../elements';
import { GET_ARTICLE_CARD_INFO } from '../../behavior/queries/content.query';
import { ContentCollection } from '../../types/content';
import { ReactComponent as HubertTipIcon } from '../../assets/images/info-icon-violet.svg';
import { PageRoute } from '../../constants';

interface HubertTipProps {
  title: string;
  subtitle: string;
  description: string;
  btnText: string;
}

const HubertTip: FC<HubertTipProps> = ({
  title,
  subtitle,
  description,
  btnText,
}) => {
  const { data, loading } = useQuery<{ contentCollection: ContentCollection }>(
    GET_ARTICLE_CARD_INFO,
    {
      variables: {
        candidateFilterInput: {
          tags: ['Dashboard Interview Tips'],
          optionalTags: [],
          pagination: {
            page: 0,
            limit: 1,
          },
        },
      },
    },
  );

  return (
    <div className="hubert-tip-wrapper">
      <div className="head-section">
        <HubertTipIcon className="hubert-tip-icon" />
        <Heading level="h4">{title}</Heading>
      </div>
      <HorizontalLine weight="semi-dark" className="mb-6" />
      <Text size="large" weight="semibold" block className="mb-5">
        {subtitle}
      </Text>
      <Text block className="mb-6">
        {description}
      </Text>
      <Button
        secondary
        to={
          !loading && data?.contentCollection.contents.length ?
            `${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${data?.contentCollection.contents[0].id}` :
            PageRoute.LEARN_WITH_HUBERT
        }
      >
        {btnText}
      </Button>
    </div>
  );
};

export default HubertTip;
