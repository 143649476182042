import { isLogged } from "behavior/security/auth.service";
import { Button, Heading, Text } from "components/elements";
import Link from "components/elements/Link";
import Header from "components/header/Header";
import TipBox from "components/preInterviewPage/TipBox";
import useReadLocalStorage from "helpers/hooks/useReadLocalStorage";
import useInterviewLink from "hooks/useInterviewLink";
import jwtDecode from "jwt-decode";
import { FC, useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { HubertUserJWTModel } from "types/authentication";
import { Optional } from "types/global";
import { Invitation } from "types/invitation";
import { getCookie } from "utils/cookieHandlers";
import { ApplicationLanguage, PageRoute, PageState } from "../../constants";
import { getArticleIdForLang } from "../publicArticles/adviceBeforeInterviewArticle/content";
import "./PreInterviewPage.scss";

type PreInterviewPageParams = {
	jobId: string;
};

const PreInterviewPage: FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const { jobId } = useParams<PreInterviewPageParams>();
	const [candidateName, setCandidateName] = useState("");
	const [interviewUrl, setInterviewUrl] = useState("");
	const [searchParams] = useSearchParams();
	const candidateToken = useReadLocalStorage<string>("candidate-hub-token", {
		parse: false,
	});
	const instantApplyInterviewLink = useInterviewLink({
		interviewType: "instant-apply",
		jobId,
	});

	console.log('PreInterviewPage', { state: location.state });

	useEffect(() => {
		if (jobId !== undefined) {
			return;
		}

		if (location.state) {
			const details = location.state as {
				interviewUrl: string;
				invitation: Invitation;
			};
			if (details.invitation && details.interviewUrl) {
				setCandidateName(details.invitation.invitee.displayName);
				setInterviewUrl(details.interviewUrl);
			}
		} else {
			console.log('PreInterview page lost it');
			navigate("/", { replace: true });
		}
	}, [location.state, navigate, searchParams, jobId]);

	useEffect(() => {
		if (jobId) {
			const signUpUrl = new URL(
				`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW}`,
				window.location.origin
			);

			let candidateName: Optional<string>;
			let token: Optional<string>;
			let redirectUrl = signUpUrl.toString();

			if (candidateToken != null) {
				const dashboardUrl = new URL(
					`${window.location.origin}${PageRoute.DASHBOARD}`
				);

				dashboardUrl.searchParams.append(
					"state",
					PageState.INTERVIEW_COMPLETED
				);

				candidateName = jwtDecode<HubertUserJWTModel>(candidateToken).name;
				token = candidateToken;
				redirectUrl = dashboardUrl.toString();
			}

			if (instantApplyInterviewLink) {
				if (token)
					instantApplyInterviewLink.searchParams.append("token", token);
				if (redirectUrl)
					instantApplyInterviewLink.searchParams.append(
						"redirectUrl",
						redirectUrl
					);

				setInterviewUrl(instantApplyInterviewLink.href);
			}

			setCandidateName((candidateName ??= ""));
		}
	}, [jobId, instantApplyInterviewLink, searchParams, candidateToken]);

	const getArticleLink = () => {
		if (isLogged()) {
			const lang =
				getCookie("preferred-language") ||
				getCookie("language") ||
				ApplicationLanguage.EN_EU;
			return `${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${getArticleIdForLang(
				lang || ""
			)}`;
		}
		const lang = getCookie("language") || ApplicationLanguage.EN_EU;
		return `${PageRoute.INTERVIEW_ADVICE}/${lang}`;
	};

	return (
		<main>
			<Header />
			<div className="pre-interview-page-wrapper">
				<div className="content-wrapper">
					<div className="header-section">
						<Heading level="h2" light className="text-2xl md:text-4xl">
							{`${t("PreInterviewPage.Title_Hello")} ${candidateName}`}
						</Heading>
						<Heading
							level="h5"
							className="text-base md:text-xl max-w-xs md:max-w-none mt-2 mx-auto"
						>
							{t("PreInterviewPage.Subtitle")}
						</Heading>
					</div>

					<TipBox />
					<div className="check-advice-link-wrapper text-sm" hidden>
						<Text>{`${t("PreInterviewPage.CheckAdviceArticle_Text1")} `}</Text>
						<Link native to={getArticleLink()} target="_blank" rel="noreferrer">
							{t("PreInterviewPage.CheckAdviceArticle_Text2")}
						</Link>
						<Text>{` ${t("PreInterviewPage.CheckAdviceArticle_Text3")}`}</Text>
					</div>
					<div className="ready-to-start-container">
						<Text weight="semibold" block className="text-base">
							{t("PreInterviewPage.ReadyToGetStarted")}
						</Text>
						<Button link to={interviewUrl} className="w-full apply-btn">
							<span className="mr-2">
								{t("PreInterviewPage.ButtonText_StartInterviewNow")}
							</span>
							<span>
								<ArrowRight />
							</span>
						</Button>
						<div className="flex flex-col items-center">
							<Text className="text-xs">
								{`${t("PreInterviewPage.PrivacyPolicyText")} `}
							</Text>
							<Link to={process.env.REACT_APP_HUBERT_PRIVACY_POLICY ?? ""}>
								<Text weight="semibold" className="text-xs">
									{t("PreInterviewPage.PrivacyPolicyLink")}
								</Text>
							</Link>
						</div>
					</div>

					{/* <div className="or-section">
            <div className="or-seperator-line" />
            <div className="or-text">{t('Registration.OrSeparator')}</div>
          </div>
          <Button size="large" secondary link>
            {t('PreInterviewPage.ButtonText_SetReminder')}
          </Button> */}
				</div>
			</div>
		</main>
	);
};

export default PreInterviewPage;
