import { FC, useState } from 'react';
import classNames from 'classnames';
import SkeletonLoader from './SkeletonLoader';

interface ImageProps {
  className?: string;
  src?: string;
  alt?: string;
  loading?: boolean;
  circle?: boolean;
  skeletonUntilFullLoad?: boolean;
}

const Image: FC<ImageProps> = ({ src, alt, className, loading, circle, skeletonUntilFullLoad }) => {
  const [loaded, setLoaded] = useState(false);
  const skeletonClass = loading ? 'skeleton-loading' : '';

  if (skeletonUntilFullLoad) {
    return (
      <>
        {!loaded && (
        <div className={`skeleton-loading ${className}`}>
          <SkeletonLoader count={1} circle={circle} />
        </div>
        )}
        <img
          src={src}
          alt={alt}
          className={classNames(className, !loaded && 'hidden')}
          onLoad={() => setLoaded(true)}
        />
      </>
    );
  }

  return (loading ? (
    <div className={`${className} ${skeletonClass}`}>
      <SkeletonLoader count={1} circle={circle} />
    </div>
  ) :
    (<img src={src} alt={alt} className={`${className}`} />));
};

Image.defaultProps = {
  className: '',
  src: '',
  alt: '',
  loading: false,
  circle: false,
  skeletonUntilFullLoad: false,
};

export default Image;
