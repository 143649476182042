import { useLazyQuery, useMutation } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { OPENED_DASHBOARD } from "../../behavior/mutations/invitation.mutation";
import { VERIFY_INVITATION } from "../../behavior/queries/invitation.query";
import { Heading } from "../../components/elements";
import LargeAlert, {
	LargeAlertProps,
} from "../../components/elements/LargeAlert";
import { PageRoute, PageState } from "../../constants";
import { Invitation, InvitationTokenPayload } from "../../types/invitation";
import { generateInterviewUrlRaw } from "../../utils/urlUtility";
import "./ProcessInvitation.scss";

interface InterviewExpiredText {
	preText: string;
	postText: string;
}

const ProcessInvitation = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const shouldExecute = useRef(true);
	const params = new URLSearchParams(location.search);
	const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: t("ProcessInvitation.loading-title"),
		subtitle: t("ProcessInvitation.loading-subtitle"),
		type: "info",
	});
	const [verifyInvitation] = useLazyQuery(VERIFY_INVITATION);
	const [interviewExpiredText, setInterviewExpiredText] = useState<InterviewExpiredText>();
	const [openedDashboard] = useMutation(OPENED_DASHBOARD);

	const verify = async (token: string): Promise<void> => {
		try {
			const jwt = jwt_decode<InvitationTokenPayload>(token);
			const response = await verifyInvitation({
				variables: {
					token,
				},
				fetchPolicy: "no-cache",
			});

			if (!response) {
				toast.error(t("AppMessages.server-error"));
				return;
			}

			if (response.error) {
				setShowAlert({
					title: t("AppMessages.server-error"),
					subtitle: "",
					type: "info",
				});
				return;
			}
			const type = response.data.invitation.__typename;
			if (type !== "Invitation") {
				if (type === "InvitationExpired") {
					const preText = t(`ProcessInvitation.${type}_Description1`);
					const postText = t(`ProcessInvitation.${type}_Description2`);

					setInterviewExpiredText({
						preText,
						postText,
					});
				}
				setShowAlert({
					title: t(`ProcessInvitation.${type}`),
					subtitle: "",
					type: "info",
				});
				return;
			}

			const invitation = response.data.invitation as Invitation;
			if (invitation.applicationId) {
				if (invitation.interviewCompleted) {
					setShowAlert({
						title: t("ProcessInvitation.InterviewCompleted"),
						subtitle: "",
						type: "info",
					});
					return;
				}

				await openedDashboard({ variables: { token, applicationId: invitation.applicationId } });
				const options = { 
					replace: true,
					state: {
						invitation,
						interviewUrl: undefined as string | undefined,
					}
				};

				const encodedEmail = encodeURIComponent(jwt.email);
				if (
					invitation.invitee.providerData &&
					invitation.invitee.providerData.length > 0
				) {
					navigate(`${PageRoute.PRE_INTERVIEW_LOGIN}?state=${PageState.INVITATION_SIGN_IN}&email=${encodedEmail}&token=${token}`, options);
				} else {
					options.state.interviewUrl = generateInterviewUrlRaw(
						invitation.interviewId,
						`${PageRoute.POST_INTERVIEW_REGISTRATION}?state=${PageState.INVITATION_POST_INTERVIEW_SIGN_UP}&email=${encodedEmail}&token=${token}`
					);

					navigate(PageRoute.PRE_INTERVIEW, options);
				}
			}
		} catch (e: any) {
			toast.error(t(e.message));
		}
	};

	useEffect(() => {
		if (!shouldExecute.current) {
			return;
		}
		shouldExecute.current = false;

		const token = params.get("token");
		if (token) {
			verify(token);
		} else {
			navigate(PageRoute.ROOT, { replace: true });
		}
	}, []);

	return (
		<div className="process-invitation-wrapper">
			{interviewExpiredText ? (
				<LargeAlert
					title={showAlert.title}
					subtitle={showAlert.subtitle}
					type={showAlert.type}
				>
					<Heading level="h4" light>
						{interviewExpiredText.preText}{" "}
						<Link to={PageRoute.INTERVIEWS} className="subtitle-link">
							{t("General.Title_MyHubert")}
						</Link>{" "}
						{interviewExpiredText.postText}
					</Heading>
				</LargeAlert>
			) : (
				<LargeAlert
					title={showAlert.title}
					subtitle={showAlert.subtitle}
					type={showAlert.type}
					button={{
						label: t("General.Button_GoTo_MyHubert"),
						to: PageRoute.ROOT,
					}}
				/>
			)}
		</div>
	);
};

export default ProcessInvitation;
