import { gql } from "@apollo/client";

export const EXCHANGE_IMPERSONATE_TOKEN = gql`
	fragment Payload on REST {
		token: String
	}
	query EXCHANGE_IMPERSONATE_TOKEN($input: Payload!) {
		accessTokenResponse(input: $input)
			@rest(
				type: "AccessTokenResponse"
				path: "/admin/impersonate/exchange"
				method: "POST"
			) {
			access_token
			refresh_token
			token_type
			expires_in
			action
		}
	}
`;
