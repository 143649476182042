import { LargeAlertProps } from 'components/elements/LargeAlert';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MiniLogoImage from '../../assets/images/hubert-logo-mini.png';
import { Heading, LargeAlert } from '../../components/elements';
import {
  BenefitsBox,
  RegistrationInviteEmailForm,
  SocialMediaRegistration,
} from '../../components/registration';
import { TPageState } from '../../constants';
import { RegistrationHeapType, RegistrationInterviewCompletedHeapType, RegistrationInvitationEmailHeapType } from '../../types/heapId';
import { Invitation } from '../../types/invitation';
import { RegistrationOrLine } from "./RegistrationOrLine";

interface InviteEmailSignUpProps {
  interviewCompleted?: boolean;
  invitation?: Invitation | null;
  state: TPageState;
  heapIds:
  | RegistrationHeapType
  | RegistrationInvitationEmailHeapType
  | RegistrationInterviewCompletedHeapType;
}

const InviteEmailSignUp: FC<InviteEmailSignUpProps> = ({
  interviewCompleted,
  invitation,
  state,
  heapIds,
}) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: "",
		subtitle: "",
		type: "info",
	});

  return (
    <div className="registration-invite-email-wrapper">
      <div className="left-section">
        <Heading level="h2" light>
          {invitation?.job?.title}
        </Heading>
        {interviewCompleted ? (
          <Heading level="h4">
            {t('RegistrationInviteEmail.Subtitle_AfterInterviewCompleted')}
          </Heading>
        ) : (
          <Heading level="h5">
            {t('RegistrationInviteEmail.Subtitle_BeforeInterviewCompleted')}
          </Heading>
        )}
        <BenefitsBox />
      </div>
      <div className="right-section">
        <img
          className="hubert-logo-mini"
          src={MiniLogoImage}
          alt="Hubert logo mini"
        />
        <Heading level="h4">{t('Registration.RegistrationTitle')}</Heading>
        { showAlert.title ? (
						<LargeAlert
							title={showAlert.title}
							subtitle={showAlert.subtitle}
							type={showAlert.type}
						/>
					) : (
          <div>
            <RegistrationInviteEmailForm setShowAlert={setShowAlert} invitation={invitation} state={state} heapIds={heapIds} />
            <RegistrationOrLine />
            <SocialMediaRegistration invitation={invitation} heapIds={heapIds} />
          </div> )
          }
      </div>
    </div>
  );
};

InviteEmailSignUp.defaultProps = {
  interviewCompleted: false,
  invitation: null,
};

export default InviteEmailSignUp;
