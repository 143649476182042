import { useMutation } from "@apollo/client";
import { SEND_BENEFITS_EMAIL } from "behavior/mutations/invitation.mutation";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import MiniLogoImage from "../../../assets/images/hubert-logo-mini.png";
import {
	Heading,
	LargeAlert,
	OrHorizontalLine,
} from "../../../components/elements";
import Header from "../../../components/header/Header";
import CompletedAlert from "../../../components/interview/CompletedAlert";
import {
	BenefitsBox,
	RegistrationInviteEmailForm,
	SocialMediaRegistration,
} from "../../../components/registration";
import { TPageState } from "../../../constants";

import { LargeAlertProps } from "../../../components/elements/LargeAlert";
import useDataSerializer from "../../../hooks/useDataSerializer";
import { REGISTRATION_INTERVIEW_COMPLETED } from "../../../utils/constants/heapIds";

export interface Candidate {
	firstName: string;
	lastName: string;
	email: string;
	phone?: string;
	isHubertUser: boolean;
	providerData: UserProviderData[];
}

interface UserProviderData {
	providerId: string;
	email: string;
}

const RealTimeScreeningPostInterviewRegister = () => {
	const { t } = useTranslation();
	const [sendBenefitsEmail] = useMutation(SEND_BENEFITS_EMAIL);
	const [searchParams] = useSearchParams();
	const { deserialize } = useDataSerializer();

	const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: "",
		subtitle: "",
		type: "info",
	});

	const pageState = useMemo(
		() => searchParams.get("state") as TPageState,
		[searchParams]
	);

	const job = useMemo(() => {
		const jobParam = searchParams.get("job");
		if (jobParam) {
			return deserialize<{ id: string; title: string }>(jobParam);
		}
	}, [deserialize, searchParams]);

	return (
		<>
			<Header />
			<div className="login-interview-completed-invite-email-wrapper">
				<CompletedAlert />
				<div className="registration-invite-email-wrapper">
					<div className="left-section">
						<Heading level="h2" light>
							{job?.title}
						</Heading>
						<Heading level="h4">
							{t("RegistrationInviteEmail.Subtitle_AfterInterviewCompleted")}
						</Heading>
						<BenefitsBox />
					</div>
					<div className="right-section">
						<img
							className="hubert-logo-mini"
							src={MiniLogoImage}
							alt="Hubert logo mini"
						/>
						<Heading level="h4">{t("Registration.RegistrationTitle")}</Heading>
						{showAlert.title ? (
							<LargeAlert
								title={showAlert.title}
								subtitle={showAlert.subtitle}
								type={showAlert.type}
							/>
						) : (
							<div>
								<RegistrationInviteEmailForm
									setShowAlert={setShowAlert}
									state={pageState}
									heapIds={REGISTRATION_INTERVIEW_COMPLETED}
								/>
								<OrHorizontalLine />
								<SocialMediaRegistration
									heapIds={REGISTRATION_INTERVIEW_COMPLETED}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RealTimeScreeningPostInterviewRegister;
