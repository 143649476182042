import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading, HorizontalLine, Image, Text } from '../elements';
import { Content } from '../../types/content';
import { PageRoute } from '../../constants';

interface NewestOnTheFeedProps {
  article: Content | null;
}

const NewestOnTheFeed: FC<NewestOnTheFeedProps> = ({ article }) => {
  const { t } = useTranslation();

  return article && (
    <div className="newest-on-your-feed-wrapper">
      <Heading level="h5">{t('LearnWithHubert.NewestOnTheFeed')}</Heading>
      <HorizontalLine weight="semi-dark" />
      <Link className="content-wrapper" to={`${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${article.id}`}>
        <div className="image-wrapper">
          <Image
            className="article-image"
            src={article.featureImage}
            alt={article.featureImageAlt ?? ''}
            skeletonUntilFullLoad
          />
        </div>
        <div className="content">
          <div className="article-tag">
            <span>{article.primaryTag}</span>
          </div>
          <Heading level="h3">
            {article.title}
          </Heading>
          <Text>
            {article.excerpt}
          </Text>
        </div>
      </Link>
    </div>
  );
};

export default NewestOnTheFeed;
