import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BooleanResponse } from "../../behavior/graphTypes/commonResponse";
import { EXISTING_EMAIL } from "../../behavior/queries/auth.query";
import { PageRoute, PageState } from "../../constants";
import TransitionPage from "../../pages/transition/TransitionPage";
import { useMyHubertSession } from "./useMyHubertSession";

const PostInstantApply = () => {
	const navigate = useNavigate();
	const shouldExecute = useRef(true);
	const [params] = useSearchParams();
	const { sessionToken, logoutUser } = useMyHubertSession();
	const [checkUser, { loading }] = useLazyQuery<
		{ isEmailUsed: BooleanResponse },
		{ email: string }
	>(EXISTING_EMAIL);

	const processEmail = useCallback(async () => {
		const email = params.get("email");
		const token = params.get("token");
		const job = params.get("job");
		const candidate = params.get("candidate");

		if (sessionToken && sessionToken.user !== null) {
			const candidateEmail = sessionToken.user?.email;
			if (email !== candidateEmail) {
				await logoutUser();
			} else {
				navigate(
					`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`
				);
				return;
			}
		} else if (email && job && candidate) {
			const isUser = (await checkUser({ variables: { email } })).data;
			let signInOrSignUpUrl = new URL(
				`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW_SIGNUP}/?state=${PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP}`,
				window.location.origin
			);

			if (isUser?.isEmailUsed.payload === true) {
				signInOrSignUpUrl = new URL(
					`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW_SIGNIN}/?state=${PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN}`,
					window.location.origin
				);
			}

			signInOrSignUpUrl.searchParams.set("email", email);
			signInOrSignUpUrl.searchParams.set("job", job);
			signInOrSignUpUrl.searchParams.set("candidate", candidate);
			if (token) signInOrSignUpUrl.searchParams.set("token", token);

			navigate(`${signInOrSignUpUrl.pathname}${signInOrSignUpUrl.search}`);
		}
	}, [sessionToken, checkUser, logoutUser, navigate, params]);

	useEffect(() => {
		if (!shouldExecute.current) {
			return;
		}
		shouldExecute.current = false;
		processEmail();
	}, [processEmail]);

	return <TransitionPage />;
};

export default PostInstantApply;
