import {
	ChangeEventHandler,
	ComponentProps,
	FC,
	FocusEventHandler,
} from "react";
import { TFunction, withTranslation } from "react-i18next";
import { Text } from "../elements";

interface CheckboxProps extends ComponentProps<"input"> {
	label?: string;
	helpText?: string;
	handleChange: ChangeEventHandler<any>;
	handleBlur: FocusEventHandler<any>;
	error?: string;
	touched?: boolean;
	t: TFunction<"translation", undefined>;
}

const Checkbox: FC<CheckboxProps> = ({
	label,
	name,
	id,
	helpText,
	handleChange,
	handleBlur,
	value,
	error,
	touched,
	t,
}) => (
	<div className="form-group">
		<div className="form-checkbox">
			<input
				id={id}
				type="checkbox"
				name={name}
				onChange={handleChange}
				onBlur={handleBlur}
				value={value}
				autoComplete="off"
			/>
			<label htmlFor={name} className="form-label-checkbox">
				{label}
			</label>
		</div>
		{helpText && (
			<Text size="small" block className="help-text">
				{helpText}
			</Text>
		)}
		{error && touched && (
			<Text size="small" block className="form-error">
				{t(error)}
			</Text>
		)}
	</div>
);

Checkbox.defaultProps = {
	label: "",
	id: "",
	helpText: "",
	error: "",
	touched: false,
};

export default withTranslation()(Checkbox);
