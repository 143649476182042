import { FC } from 'react';
import { Text } from '../../elements';

interface QuestionProps {
  question: string;
  answer: string;
  loading?: boolean
}

const Question: FC<QuestionProps> = ({ question, answer, loading }) => (
  <div className="question-wrapper skeleton-loading">
    <Text weight="semibold" block html className="mb-3" loading={loading}>
      {question}
    </Text>
    <Text block html>{answer}</Text>
  </div>
);

Question.defaultProps = {
  loading: false,
};

export default Question;
