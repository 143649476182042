import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storeTokens } from 'behavior/security/auth.service';
import jwt_decode from 'jwt-decode';
import {
  JwtModel,
  TokenModel,
  UserInformation,
} from '../../types/authentication';

const initialState: UserInformation = {
  access_token: '',
  refresh_token: '',
  token_type: '',
  expires_in: 0,
  action: '',
  username: '',
  displayName: '',
  jwt: {
    iss: '',
    aud: '',
    auth_time: 0,
    email: '',
    exp: 0,
    iat: 0,
    sub: '',
    user_id: '',
    role: '',
    cid: '',
  },
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: state => {
      state.username = '';
      state.displayName = '';
      state.jwt = {
        iss: '',
        aud: '',
        auth_time: 0,
        email: '',
        exp: 0,
        iat: 0,
        sub: '',
        user_id: '',
        role: '',
        cid: '',
      };
    },
    setSession: (state, action: PayloadAction<TokenModel | undefined>) => {
      if (action.payload != null) {
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.token_type = action.payload.token_type;
        state.expires_in = action.payload.expires_in;

        const jwt = jwt_decode<JwtModel>(action.payload.access_token);
        state.jwt = jwt;
        state.username = jwt.email;

        storeTokens(action.payload);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetSession, setSession } = sessionSlice.actions;

export default sessionSlice.reducer;
