import { useMutation } from '@apollo/client';
import { FC } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { VIEWER_OPENED_DASHBOARD } from '../../../behavior/mutations/viewer.mutation';
import { Button } from '../../elements';
import OldInterviewRedirectModal from './OldInterviewRedirectModal';

interface StartInterviewButtonProps {
  btnLabel: string;
  applicationId: string;
  interviewId: string;
  hidden?: boolean;
  oldInterviewFlow?: boolean;
  redirectToInterview: (applicationId: string, interviewId: string) => void;
}

const StartInterviewButton: FC<StartInterviewButtonProps> = ({
  btnLabel,
  applicationId,
  interviewId,
  hidden,
  oldInterviewFlow,
  redirectToInterview,
}) => {
  const [viewerOpenedDashboard] = useMutation(VIEWER_OPENED_DASHBOARD);
  const [ModalProvider, openModal, closeModal] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const startInterview = async () => {
    await viewerOpenedDashboard({
      variables: { applicationId },
    });

    if (oldInterviewFlow) {
      openModal();
    } else {
      redirectToInterview(applicationId, interviewId);
    }
  };

  return (
    <>
      <Button
        size="small"
        hidden={hidden}
        onClick={async () => startInterview()}
      >
        {btnLabel}
      </Button>
      <ModalProvider>
        <OldInterviewRedirectModal applicationId={applicationId} interviewId={interviewId} close={closeModal} />
      </ModalProvider>
    </>
  );
};

StartInterviewButton.defaultProps = {
  hidden: undefined,
  oldInterviewFlow: false,
};

export default StartInterviewButton;
