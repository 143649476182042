import { Dispatch, FC, SetStateAction } from 'react';
import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Nav from './Nav';
import UserDropdown from './UserDropdown';
import LanguageSelector from './LanguageSelector';
import { HorizontalLine, Image } from '../elements';
import HubertLogo from '../../assets/images/hubert-logo.png';
import { Viewer } from '../../types/viewer';
import { GET_VIEWER_INFORMATION } from '../../behavior/queries/viewer.query';
import { PageRoute } from '../../constants';

interface MobileNavExpandedProps {
  isMobile: boolean;
  setNavbarExpanded: Dispatch<SetStateAction<boolean>>;
}

const MobileNavExpanded: FC<MobileNavExpandedProps> = ({
  isMobile,
  setNavbarExpanded,
}) => {
  const { data } = useQuery<{ viewer: Viewer }>(GET_VIEWER_INFORMATION);

  return (
    <div className="mobile-nav-expanded-wrapper">
      <div className="head-section">
        <Link to={PageRoute.DASHBOARD}>
          <Image className="hubert-logo" src={HubertLogo} alt="hubert-logo" />
        </Link>
        <X
          className="close-icon"
          onClick={() => setNavbarExpanded(prevState => !prevState)}
        />
      </div>
      <Nav isMobile={isMobile} />
      <HorizontalLine weight="semi-dark" />
      <LanguageSelector
        isMobile={isMobile}
        language={data?.viewer.language}
      />
      <HorizontalLine weight="semi-dark" />
      <UserDropdown isMobile={isMobile} />
    </div>
  );
};

export default MobileNavExpanded;
