import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '../../elements';
import './InterviewNotFound.scss';
import { PageRoute } from '../../../constants';

interface InterviewNotFoundProps {}

const InterviewNotFound: FC<InterviewNotFoundProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="interview-not-found-wrapper">
      <Heading level="h1" light>{t('Interview.InterviewNotFound_Title')}</Heading>
      <Text block size="large" className="mt-1 mb-10">{t('Interview.InterviewNotFound_Description')}</Text>
      <Button to={PageRoute.INTERVIEWS}>
        {t('Interview.ButtonText_GoToAllInterviews')}
      </Button>
    </div>
  );
};

export default InterviewNotFound;
