import * as Yup from 'yup';

const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .required('Login.Error_Email_Required')
    .email('Login.Error_Email_Invalid'),
  password: Yup.string().required('Login.Error_Password_Required'),
});

export default loginFormSchema;
