import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useModal } from "react-hooks-use-modal";
import { useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import { YourCareer, LearnWithHubert } from "../../components/dashboard";
import {
	YourInterviewsTable,
	HubertTip,
	ArticleLinkCard,
} from "../../components/common";
import Footer from "../../components/footer/Footer";
import InterviewCompletedModal from "../../components/dashboard/InterviewCompletedModal";
import NewCandidateModalPopup from "../../components/dashboard/NewCandidateModalPopup";
import { Viewer } from "../../types/viewer";
import { GET_VIEWER_INTERVIEW_SUMMARY } from "../../behavior/queries/application.query";
import Application from "../../types/application";
import "./Dashboard.scss";
import LatestJobPostings from "../../components/dashboard/latestJobPostings";
import { PageState } from "../../constants";

const Dashboard = () => {
	const { t } = useTranslation();
	const { data, loading } = useQuery<{ viewer: Viewer }>(
		GET_VIEWER_INTERVIEW_SUMMARY
	);
	const [recentInterview, setRecentInterview] = useState<Application>();
	const [todoInterview, setTodoInterview] = useState<Application>();
	const location = useLocation();
	const [
		NewCandidateModalProvider,
		openNewCandidateModal,
		closeNewCandidateModal,
	] = useModal("root", {
		preventScroll: true,
		closeOnOverlayClick: false,
	});
	const [
		InterviewCompletedModalProvider,
		openInterviewCompletedModal,
		closeInterviewCompletedModal,
	] = useModal("root", {
		preventScroll: true,
		closeOnOverlayClick: false,
	});

	const loadSummary = async () => {
		if (!loading && data) {
			const recentInterviewData = data?.viewer.dashboardSummary
				?.recentInterview as Application;

			if (recentInterviewData) {
				setRecentInterview(recentInterviewData);
			}
			const todoInterviewData = data?.viewer.dashboardSummary
				?.todoInterview as Application;
			if (todoInterviewData) {
				setTodoInterview(todoInterviewData);
			}
		}
	};

	useEffect(() => {
		if (location.state) {
			const pageState = location.state as { emailVerified: boolean };
			if (pageState.emailVerified) {
				openNewCandidateModal();
				window.history.replaceState({}, "");
			}
		}
		const params = new URLSearchParams(location.search);
		const state = params.get("state");
		if (state === PageState.INTERVIEW_COMPLETED) {
			openInterviewCompletedModal();
		}
	}, [location]);

	useEffect(() => {
		loadSummary();
	}, [data]);

	return (
		<>
			<Header type="LoggedIn" />
			<div className="dashboard-wrapper">
				<YourCareer
					recentInterview={recentInterview}
					todoInterview={todoInterview}
					loading={loading}
				/>
				<YourInterviewsTable forDashboard />
				<LatestJobPostings />
				<LearnWithHubert />
				<div className="tip-and-guide-wrapper">
					<div className="section">
						<HubertTip
							title={t("Dashboard.HubertTip.Title")}
							subtitle={t("Dashboard.HubertTip.Subtitle")}
							description={t("Dashboard.HubertTip.Description")}
							btnText={t("Dashboard.HubertTip.ButtonText_ReadMoreTips")}
						/>
					</div>
					<div className="section">
						<ArticleLinkCard />
					</div>
				</div>
			</div>
			<Footer />
			<NewCandidateModalProvider>
				<NewCandidateModalPopup close={closeNewCandidateModal} />
			</NewCandidateModalProvider>
			<InterviewCompletedModalProvider>
				<InterviewCompletedModal close={closeInterviewCompletedModal} />
			</InterviewCompletedModalProvider>
		</>
	);
};

export default Dashboard;
