import { ElementType } from "react";
import { IconProps } from "react-feather";

interface HeaderHighlightGroupProps {
	title: string;
	IconStart?: ElementType<IconProps>;
	IconEnd?: ElementType<IconProps>;
}

const HeaderHighlightGroup = ({
	IconStart,
	title,
	IconEnd,
}: HeaderHighlightGroupProps) => {
	return (
		<div className="highlight-group">
			{IconStart && <IconStart size={16} />}
			<span className="text-xs">{title}</span>
			{IconEnd && <IconEnd size={16} />}
		</div>
	);
};

export default HeaderHighlightGroup;
