import classNames from 'classnames';
import { FC } from 'react';

interface HorizontalLineProps {
  weight: 'light' | 'regular' | 'medium' | 'semi-dark' | 'dark';
  className?: string;
}

const HorizontalLine: FC<HorizontalLineProps> = ({ weight, className }) => (
  <div className={classNames('horizontal-line', weight, className)} />
);

HorizontalLine.defaultProps = {
  className: '',
};

export default HorizontalLine;
