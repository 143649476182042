import {
	ChangeEventHandler,
	ComponentProps,
	FC,
	FocusEventHandler,
	useState,
} from "react";
import { TFunction, withTranslation } from "react-i18next";
import classNames from "classnames";
import { Eye, EyeOff } from "react-feather";
import { Text } from "../elements";
import SkeletonLoader from "../elements/SkeletonLoader";

interface PasswordProps extends ComponentProps<"input"> {
	label?: string;
	helpText?: string;
	handleChange: ChangeEventHandler<any>;
	handleBlur: FocusEventHandler<any>;
	error?: string;
	touched?: boolean;
	t: TFunction<"translation", undefined>;
	loading?: boolean;
}

const Password: FC<PasswordProps> = ({
	label,
	name,
	id,
	placeholder,
	helpText,
	handleChange,
	handleBlur,
	value,
	error,
	touched,
	t,
	loading,
	disabled,
}) => {
	const [passwordVisibility, setPasswordVisibility] = useState(false);

	return loading ? (
		<SkeletonLoader height="50" />
	) : (
		<div className="form-group form-password">
			<label
				htmlFor={name}
				className={classNames("form-label", !label && "h-5")}
			>
				{label}
			</label>
			<input
				id={id}
				className={classNames(
					"form-input",
					error && touched && "error",
					disabled && "disabled"
				)}
				type={passwordVisibility ? "text" : "password"}
				name={name}
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
				value={value}
				autoComplete="off"
				disabled={disabled}
			/>
			{passwordVisibility ? (
				<Eye
					size={20}
					onClick={() => setPasswordVisibility((prevState) => !prevState)}
					className="visibility-toggler-icon"
				/>
			) : (
				<EyeOff
					size={20}
					onClick={() => setPasswordVisibility((prevState) => !prevState)}
					className="visibility-toggler-icon"
				/>
			)}
			{helpText && (
				<Text size="small" block className="help-text">
					{helpText}
				</Text>
			)}
			{error && touched && (
				<Text size="small" block className="form-error">
					{t(error)}
				</Text>
			)}
		</div>
	);
};

Password.defaultProps = {
	label: "",
	id: "",
	placeholder: "",
	helpText: "",
	error: "",
	touched: false,
	loading: false,
	disabled: false,
};

export default withTranslation()(Password);
