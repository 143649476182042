import { FC } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface SkeletonLoaderProps {
  count?: number;
  containerClassName?: string;
  circle? : boolean
  width?: string | number
  height?: string | number
  className?: string;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  count,
  containerClassName,
  circle,
  width,
  height,
  className }) => (
    <SkeletonTheme>
      <Skeleton
        count={count}
        className={className}
        containerClassName={containerClassName}
        circle={circle}
        width={width}
        height={height}
      />
    </SkeletonTheme>
);

SkeletonLoader.defaultProps = {
  count: 1,
  className: undefined,
  containerClassName: 'skeleton-loading',
  circle: false,
  width: '100%',
  height: '100%',
};

export default SkeletonLoader;
