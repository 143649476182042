import classNames from "classnames";
import { ComponentProps, ElementType } from "react";
import { IconProps } from "react-feather";
import { Link } from "react-router-dom";
import ConditionalComponent from "../common/ConditionalComponent";

interface ButtonProps extends ComponentProps<"button"> {
	submit?: boolean;
	size?: "small" | "large" | "";
	secondary?: boolean;
	link?: boolean;
	fullWidth?: boolean;
	imgIcon?: string;
	StartIcon?: ElementType<IconProps>;
	EndIcon?: ElementType<IconProps>;
	to?: string;
}

const Button = ({
	submit = false,
	size = "",
	secondary = false,
	link = false,
	fullWidth = false,
	imgIcon,
	to,
	children,
	disabled = false,
	className,
	StartIcon,
	EndIcon,
	...buttonProps
}: ButtonProps) => {
	const isLink = to && !disabled;

	return (
		<ConditionalComponent
			condition={isLink === true && to !== undefined}
			wrapper={(children) => <Link to={to!}>{children}</Link>}
		>
			<button
				{...buttonProps}
				type={submit || buttonProps.type === "submit" ? "submit" : "button"}
				className={classNames(
					link ? "btn-link" : "btn",
					size === "small" && (isLink ? "btn-link-sm" : "btn-sm"),
					size === "large" && (isLink ? "btn-link-lg" : "btn-lg"),
					secondary && "btn-secondary",
					fullWidth && "w-full",
					className
				)}
				disabled={disabled}
			>
				<div className="button-content-wrapper">
					{imgIcon && (
						<img src={imgIcon} alt="Button icon" className="button-img-icon" />
					)}
					{StartIcon && <StartIcon />}
					{children}
					{EndIcon && <EndIcon />}
				</div>
			</button>
		</ConditionalComponent>
	);
};

export default Button;
