import classNames from 'classnames';
import { FC, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Text } from '../elements';

interface FAQItemProps {
  questionText: string;
  answerText: string;
}

const FAQItem: FC<FAQItemProps> = ({ questionText, answerText }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div
      className="faq-item"
      onClick={() => setExpand(prevState => !prevState)}
      onKeyDown={() => setExpand(prevState => !prevState)}
      role="button"
      tabIndex={0}
    >
      <div className="question">
        <Text>{questionText}</Text>
        {expand ? (
          <ChevronUp className="mr-4 text-violet" />
        ) : (
          <ChevronDown className="mr-4" />
        )}
      </div>
      <div className={classNames('answer', expand && 'expanded')}>
        {answerText}
      </div>
    </div>
  );
};

export default FAQItem;
