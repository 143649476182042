import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { AlertCircle, AlertTriangle, CheckCircle, Info } from 'react-feather';

interface AlertProps {
  type: 'loading' | 'success' | 'info' | 'warning' | 'danger';
  children: ReactNode;
  className?: string;
}

const Alert: FC<AlertProps> = ({ type, className, children }) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle size={24} />;
      case 'info':
        return <Info size={24} />;
      case 'warning':
        return <AlertTriangle size={24} />;
      case 'danger':
        return <AlertCircle size={24} />;
      default:
        return '';
    }
  };

  return (
    <div className={classNames('alert-wrapper', type, className)}>
      <div className="content-wrapper">
        {getIcon()}
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  className: '',
};

export default Alert;
