import { FC, ReactNode } from 'react';
import SkeletonLoader from './SkeletonLoader';

interface HeadingProps {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  light?: boolean;
  className?: string;
  children?: string | ReactNode;
  loading?: boolean;
}

const Heading: FC<HeadingProps> = ({ level, light, className, children, loading }) => {
  const HeadingTag = level;

  return (
    <HeadingTag className={`${className} ${light && 'light'}`}>
      { loading ? <SkeletonLoader count={1} /> : children}
    </HeadingTag>
  );
};

Heading.defaultProps = {
  light: false,
  className: '',
  children: '',
  loading: false,
};

export default Heading;
