import ScoreIconSmallPositive from '../../../../assets/images/score-icon-small-positive.png';
import ScoreIconSmallGotPotential from '../../../../assets/images/score-icon-small-got-potential.png';
import ScoreIconSmallOkMatch from '../../../../assets/images/score-icon-small-ok-match.png';
import ScoreIconSmallNotClearMatch from '../../../../assets/images/score-icon-small-not-clear-match.png';
import ScoreIconSmallNegative from '../../../../assets/images/score-icon-small-negative.png';

export const assessedMatch = [
  {
    title: 'Interview.HowHubertScoresInterviews.GreatMatch_Title',
    description: 'Interview.HowHubertScoresInterviews.GreatMatch_Description',
    image: ScoreIconSmallPositive,
    imageAltText: 'Great match',
  },
  {
    title: 'Interview.HowHubertScoresInterviews.GotPotential_Title',
    description: 'Interview.HowHubertScoresInterviews.GotPotential_Description',
    image: ScoreIconSmallGotPotential,
    imageAltText: 'Got potential',
  },
  {
    title: 'Interview.HowHubertScoresInterviews.OkMatch_Title',
    description: 'Interview.HowHubertScoresInterviews.OkMatch_Description',
    image: ScoreIconSmallOkMatch,
    imageAltText: 'Ok match',
  },
  {
    title: 'Interview.HowHubertScoresInterviews.NotClearMatch_Title',
    description:
      'Interview.HowHubertScoresInterviews.NotClearMatch_Description',
    image: ScoreIconSmallNotClearMatch,
    imageAltText: 'Not a clear match',
  },
];

export const professionalExperiences = [
  {
    title: 'Interview.HowHubertScoresInterviews.GreatResults_Title',
    description: 'Interview.HowHubertScoresInterviews.GreatResults_Description',
    image: ScoreIconSmallPositive,
    imageAltText: 'Great results',
  },
  {
    title: 'Interview.HowHubertScoresInterviews.NotQualified_Title',
    description: 'Interview.HowHubertScoresInterviews.NotQualified_Description',
    image: ScoreIconSmallNegative,
    imageAltText: 'Below average results',
  },
];

export const qualifyingRequirements = [
  {
    title: 'Interview.HowHubertScoresInterviews.MeetingRequirements_Title',
    description:
      'Interview.HowHubertScoresInterviews.MeetingRequirements_Description',
    image: ScoreIconSmallPositive,
    imageAltText: 'Meeting requirements',
  },
  {
    title: 'Interview.HowHubertScoresInterviews.NotAllExpectationsMet_Title',
    description:
      'Interview.HowHubertScoresInterviews.NotAllExpectationsMet_Description',
    image: ScoreIconSmallNegative,
    imageAltText: 'Not all expectations met',
  },
];
