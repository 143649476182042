import { useMutation } from "@apollo/client";
import { BooleanResponse } from "behavior/graphTypes/commonResponse";
import { LargeAlertProps } from "components/elements/LargeAlert";
import Link from "components/elements/Link";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "types/user";
import {
	EmailTokenResponse,
	RTSTokenResponse,
} from "../../behavior/graphTypes/authenticationTypes";
import { useAppDispatch } from "../../behavior/hooks";
import {
	SIGNUP_EMAIL_PASSWORD,
	SIGNUP_EMAIL_RTS_TOKEN,
	SIGNUP_EMAIL_TOKEN,
} from "../../behavior/mutations/auth.mutation";
import { setSession } from "../../behavior/reducers/sessionSlice";
import {
	ApplicationLanguage,
	PageRoute,
	PageState,
	TPageState,
} from "../../constants";
import {
	RegistrationInterviewCompletedHeapType,
	RegistrationInvitationEmailHeapType,
} from "../../types/heapId";
import { Invitation, InvitationTokenPayload } from "../../types/invitation";
import { getCookie } from "../../utils/cookieHandlers";
import { generateInterviewUrlRaw } from "../../utils/urlUtility";
import { registrationInviteEmailFormSchema } from "../../utils/validationSchemas";
import { Button, Text } from "../elements";
import { Password, Textbox } from "../forms";

interface RegistrationInviteEmailProps {
	setShowAlert: Dispatch<SetStateAction<LargeAlertProps>>;
	invitation?: Invitation | null;
	state: TPageState;
	heapIds:
		| RegistrationInvitationEmailHeapType
		| RegistrationInterviewCompletedHeapType;
}

const RegistrationInviteEmailForm: FC<RegistrationInviteEmailProps> = ({
	setShowAlert,
	invitation,
	state,
	heapIds,
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [signUpEmailPassword, { loading: loadingSignUpPassword }] = useMutation<
		any,
		{ registerUserInput: User }
	>(SIGNUP_EMAIL_PASSWORD);

	const [signUpEmailToken, { loading: loadingSignUpToken }] =
		useMutation<EmailTokenResponse>(SIGNUP_EMAIL_TOKEN);

	const [signUpRTSToken, { loading: loadingSignUpRTSToken }] =
		useMutation<RTSTokenResponse>(SIGNUP_EMAIL_RTS_TOKEN);

	const normalSignUpButton = useMemo(() => {
		return (
			state === PageState.INTERVIEW_COMPLETED ||
			state === PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP
		);
	}, [state]);

	const signUpWithInvitationToken = async (
		email: string,
		password: string,
		token: string
	) => {
		try {
			const data = await signUpEmailToken({
				variables: {
					registerUserByTokenInput: {
						email,
						password,
						token,
						language: getCookie("language") ?? ApplicationLanguage.EN_EU,
					},
				},
			});
			const response = data.data?.signUpUsingToken;
			if (!response) {
				toast.error(t("AppMessages.server-error"));
				return;
			}
			if (response.isError && response.errorMessage) {
				toast.error(t(`AppMessages.${response.errorMessage}`));
			} else {
				dispatch(setSession(response.payload));
				if (
					state === PageState.SIGN_UP ||
					state === PageState.INTERVIEW_COMPLETED
				) {
					navigate(PageRoute.DASHBOARD, {
						replace: true,
					});
					return;
				}
				const jwt = jwt_decode<InvitationTokenPayload>(token);
				const interviewStartUrl = generateInterviewUrlRaw(
					jwt.interviewId,
					`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`,
					response.payload?.access_token
				);
				navigate(PageRoute.PRE_INTERVIEW, {
					replace: true,
					state: { interviewUrl: interviewStartUrl, invitation },
				});
			}
		} catch (e: any) {
			toast.success(t(e.message));
		}
	};

	const signUpWithRTSToken = async (
		email: string,
		password: string,
		token: string
	) => {
		try {
			const data = await signUpRTSToken({
				variables: {
					registerUserByTokenInput: {
						email,
						password,
						token,
						language: getCookie("language") ?? ApplicationLanguage.EN_EU,
					},
				},
			});
			const response = data.data?.signUpUsingRTSToken;
			if (!response) {
				toast.error(t("AppMessages.server-error"));
				return;
			}
			if (response.isError && response.errorMessage) {
				toast.error(t(`AppMessages.${response.errorMessage}`));
			} else {
				dispatch(setSession(response.payload));
				navigate(
					`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`
				);
			}
		} catch (e: any) {
			toast.success(t(e.message));
		}
	};

	const signUp = async (email: string, password: string) => {
		try {
			const response = await signUpEmailPassword({
				variables: {
					registerUserInput: {
						firstName: "",
						lastName: "",
						email: email,
						password,
						phoneNumber: "",
						countryCode: "",
						language: getCookie("language") ?? ApplicationLanguage.EN_EU,
					},
				},
			});
			const type = response.data?.signUpUsingPassword.__typename;
			if (type === "BooleanResponse") {
				const booleanResponse = response.data
					.signUpUsingPassword as BooleanResponse;
				if (booleanResponse.payload) {
					setShowAlert({
						title: t("AppMessages.verify-email-title"),
						subtitle: t("AppMessages.verify-email-subtitle"),
						type: "info",
					});
				} else if (booleanResponse.errorMessage === "email-is-taken") {
					toast.error(t("Registration.Error_Email_Exists"));
					return;
				}
				return;
			}
			toast.error(t("AppMessages.server-error"));
		} catch (e: any) {
			toast.error(t(e.message));
		}
	};

	const {
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldValue,
	} = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: registrationInviteEmailFormSchema,
		onSubmit: async (props) => {
			if (props) {
				const token = searchParams.get("token");
				if (token) {
					if (state === PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP) {
						return await signUpWithRTSToken(props.email, props.password, token);
					} else {
						return await signUpWithInvitationToken(
							props.email,
							props.password,
							token
						);
					}
				} else {
					return await signUp(props.email, props.password);
				}
			}
		},
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const email = invitation?.invitee.email ?? params.get("email");

		if (email) {
			setFieldValue("email", email);
		}
	}, [invitation, location.search, setFieldValue]);

	const emailPasswordSet = values.email && values.password && !errors.email && !errors.password;
	const isLoading = loadingSignUpPassword || loadingSignUpToken || loadingSignUpRTSToken;
	const canSubmit = emailPasswordSet && !isLoading;

	return (
		<form className="registration-invite-email-form" onSubmit={handleSubmit}>
			<Textbox
				id={heapIds.INPUT_EMAIL}
				label={`${t("Registration.Label_Email")}*`}
				name="email"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.email}
				error={errors.email}
				touched={touched.email}
				disabled
			/>
			<Password
				id={heapIds.INPUT_PASSWORD}
				label={`${t("Registration.Label_Password")}*`}
				name="password"
				helpText={t("Registration.HelpText_Password")}
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.password}
				error={errors.password}
				touched={touched.password}
			/>
			<div className="terms-and-condition-text-wrapper">
				<Text size="small">
					{`${t("RegistrationInviteEmail.PrivacyPolicyText")} `}
				</Text>
				<Link
					to={"https://www.hubert.ai/candidate-privacy-policy"}
					target="_blank"
					rel="noreferrer"
				>
					<Text size="small" weight="semibold">
						{t("RegistrationInviteEmail.PrivacyPolicyLink")}
					</Text>
					.
				</Link>
			</div>
			<Button
				id={heapIds.BUTTON_SIGNUP}
				fullWidth
				submit
				className="mt-2"
				disabled={!canSubmit}
			>
				{normalSignUpButton
					? t("RegistrationInviteEmail.ButtonText_SignUpNormal")
					: t("RegistrationInviteEmail.ButtonText_SignUp")}
			</Button>
		</form>
	);
};

RegistrationInviteEmailForm.defaultProps = {
	invitation: null,
};

export default RegistrationInviteEmailForm;
