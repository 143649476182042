import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'react-feather';
import classNames from 'classnames';

interface BenefitsBoxProps {
  large?: boolean;
}

type BenefitPointType = {
  textLabel: string;
};

const BenefitPoint = ({ textLabel }: BenefitPointType) => (
  <div className="benefit-point">
    <Check />
    <span>{textLabel}</span>
  </div>
);

const BenefitsBox: FC<BenefitsBoxProps> = ({ large }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('benefits-box-wrapper', large && 'large')}>
      <div className="benefit-box-title">
        {`${t('Registration.BenefitsBox_Title')}:`}
      </div>
      <BenefitPoint textLabel={t('Registration.BenefitsBox_Point1')} />
      <BenefitPoint textLabel={t('Registration.BenefitsBox_Point2')} />
      <BenefitPoint textLabel={t('Registration.BenefitsBox_Point3')} />
      <BenefitPoint textLabel={t('Registration.BenefitsBox_Point4')} />
      <BenefitPoint textLabel={t('Registration.BenefitsBox_Point5')} />
      <BenefitPoint textLabel={t('Registration.BenefitsBox_Point6')} />
    </div>
  );
};

BenefitsBox.defaultProps = {
  large: false,
};

export default BenefitsBox;
