import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, HorizontalLine, Text } from '../elements';

export interface InterviewDetailsProps {
  jobTitle?: string;
  company?: string;
  dateTime?: Date;
  loading?: boolean;
}

const InterviewDetails: FC<InterviewDetailsProps> = ({
  jobTitle,
  company,
  dateTime,
  loading,
}) => {
  const { t } = useTranslation();

  const parsedDate = dateTime && new Date(dateTime);

  const date = parsedDate && parsedDate.toLocaleDateString(t('locale'), { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
  const time = parsedDate && parsedDate.toLocaleTimeString(t('locale'), { hour: 'numeric', minute: 'numeric' });

  return (
    <div className="interview-details-wrapper">
      <Heading level="h5">{t('Interview.InterviewDetails.Title')}</Heading>
      <HorizontalLine weight="semi-dark" />
      <div className="content-section">
        <Heading level="h3" loading={loading}>
          {jobTitle}
        </Heading>
        <Heading level="h3" light>
          {company}
        </Heading>
        <Text size="small" className="mt-2" block loading={loading}>
          {date && time && t('Interview.InterviewDetails.InterviewCompletedOn', {
            date,
            time,
          })}
        </Text>
      </div>
    </div>
  );
};

InterviewDetails.defaultProps = {
  jobTitle: '',
  company: '',
  dateTime: undefined,
  loading: false,
};

export default InterviewDetails;
