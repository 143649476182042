import { useQuery } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GET_APPLICATION_DETAIL } from "../../behavior/queries/application.query";
import { Button } from "../../components/elements";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import InterviewDetails, {
	InterviewDetailsProps,
} from "../../components/interview/InterviewDetails";
import HowToImproveAtInterviews from "../../components/interview/howToImproveAtInterviews/HowToImproveAtInterviews";
import InterviewNotFound from "../../components/interview/interviewNotFound/InterviewNotFound";
import ScriptFromInterview from "../../components/interview/scriptFromInterview/ScriptFromInterview";
import YourScore, {
	YourScoreProps,
} from "../../components/interview/yourScore/YourScore";
import { AssessedMatch, PageRoute, ProfessionalExperience } from "../../constants";
import Application, { Feedback } from "../../types/application";
import "./Interview.scss";

const Interview: FC = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const vars = { applicationId: id };
	const { data, loading } = useQuery<{
		application: Application;
	}>(GET_APPLICATION_DETAIL, {
		variables: vars,
	});

	const [interviewDetail, setInterviewDetail] =
		useState<InterviewDetailsProps>();
	const [interviewScore, setInterviewScore] = useState<YourScoreProps>({
		assessedMatch: AssessedMatch.OK_MATCH,
		professionalExperience: ProfessionalExperience.OK_MATCH,
		qualified: false,
	});
	const [interviewFeedback, setInterviewFeedback] = useState<Feedback[]>([]);
	const [interviewId, setInterviewId] = useState<string>("");
	const [isLegacyInterview, setIsLegacyInterview] = useState<boolean>(false);

	const loadInterviewDetails = async () => {
		if (!loading && data?.application) {
			const { application } = data;
			if (application.id) {
				if (application.job) {
					const detail: InterviewDetailsProps = {
						jobTitle: application.job.title,
						company: application.job.company,
						...(application.completedAt && {
							dateTime: application.completedAt,
						}),
					};

					const scoreDetail: YourScoreProps = {
						qualified: application.qualified,
						assessedMatch: AssessedMatch[application.assessedMatch],
						professionalExperience:
							ProfessionalExperience[application.professionalExperience],
					};

					if (application.interview) {						
						setInterviewId(application.interview.id);
					}

					setInterviewDetail(detail);
					setInterviewScore(scoreDetail);
					setInterviewFeedback(application.feedback);
					setIsLegacyInterview(application.job.templateVersion === 1);
				}
			}
		}
	};

	useEffect(() => {
		loadInterviewDetails();
	}, [data]);

	return (
		<>
			<Header type="LoggedIn" />
			{(loading || data?.application.id) && (
				<div className="interview-wrapper">
					<Button secondary link to={PageRoute.INTERVIEWS} className="back-button">
						<ArrowLeft size={20} className="mr-2" />
						<div>{t("Interview.ButtonText_SeeAllInterviews")}</div>
					</Button>

					<InterviewDetails
						jobTitle={interviewDetail?.jobTitle}
						company={interviewDetail?.company}
						dateTime={interviewDetail?.dateTime}
						loading={loading}
					/>
					<YourScore
						professionalExperience={interviewScore.professionalExperience}
						assessedMatch={interviewScore.assessedMatch}
						qualified={interviewScore.qualified}
						loading={loading}
					/>
					{!loading && (
						<ScriptFromInterview
							interviewId={interviewId}
							isLegacyInterview={isLegacyInterview}
						/>
					)}
					<div className="gray-bg-wrapper">
						<HowToImproveAtInterviews
							scoreType={interviewScore.assessedMatch}
							questions={interviewFeedback.length > 0}
							feedbacks={interviewFeedback}
							loading={loading}
						/>
					</div>
				</div>
			)}
			{!loading && !data?.application.id && <InterviewNotFound />}
			<Footer />
		</>
	);
};

export default Interview;
