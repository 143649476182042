import classNames from "classnames";
import Dialog from "components/common/Dialog/Dialog";
import { useEffect, useMemo, useRef, useState } from "react";
import { Clock, MapPin } from "react-feather";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "utils/constants/breakpoints";
import HeaderHighlightGroup from "./components/HeaderHighlightGroup";
import demoPages, { getPreInterviewLink } from "./data";
import "./JobLandingPage.scss";

type JobLandingPageParams = {
	demoId: string;
	jobId: string;
};

const JobLandingPage = () => {
	const { jobId, demoId } = useParams<JobLandingPageParams>();
	const { breakpoint } = useBreakpoint(BREAKPOINTS);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const isMobile = breakpoint === "sm" || breakpoint === "md";
	const demoPage = useMemo(
		() => demoPages.find((e) => e.id === demoId),
		[demoId]
	);

	const headerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (headerRef.current) {
			const observer = new IntersectionObserver(
				([entry]) => {
					if (headerRef.current?.dataset) {
						headerRef.current.dataset["isBelowTop"] = (
							entry.intersectionRatio >= 1
						).toString();
					}
				},
				{
					root: null,
					threshold: [1],
				}
			);

			observer.observe(headerRef.current!);

			return () => {
				observer.disconnect();
			};
		}
	}, []);

	if (!demoPage) {
		return <div>Demo page not found</div>;
	}

	return (
		<>
			<Helmet>
				<title>Job | {demoPage.title}</title>
			</Helmet>
			<header className="navigation-bar">
			</header>
			<img
				src={demoPage.coverImage}
				alt=""
				className="cover-image"
			/>
			<main className="job-landing-page">

					<div ref={headerRef} className="job-header">
						<div className="key-info">
							<h1 className="text-2xl text-center md:text-start">
								{demoPage.title}
							</h1>
							<HeaderHighlightGroup
								title={`${demoPage.location} (${demoPage.mode})`}
								IconStart={MapPin}
							/>
							<div className="spotlight">
								<HeaderHighlightGroup IconStart={Clock} title={demoPage.type} />
								<HeaderHighlightGroup title={demoPage.salary} />
							</div>

							<div className="flex justify-around">
								{!isMobile && (
									<start-interview-button
										job-id={jobId}
										open-in="popup"
										disable-overlay
									>
										{"Apply Now"}
									</start-interview-button>
								)}
							</div>
						</div>

					</div>

				<article>
					{/* Vivatech Alert for instructions */}
					{/* <div aria-labelledby="alert-title" role="alert" className="alert">
						<div className="icon-container">
							<Info className="icon" />
						</div>
						<div>
							<h2 id="alert-title">For VivaTech visitors</h2>
							{job.vivatechDescription.map((d, index) => (
								<p key={index}>{d}</p>
							))}
						</div>
					</div> */}
					{demoPage.content ? (
						demoPage.content.map((item, itemIndex) => {
							return (
								<section key={itemIndex}>
									<h2>{item.header}</h2>
									<p>
										{item.text.map((itemText, itemIndex) => (
											<span key={itemIndex}>{itemText}</span>
										))}
									</p>
								</section>
							)
						})
					):(
						<section>
						<h2>About the company</h2>
						<p>
							{demoPage.about ? (
								typeof demoPage.about === "string"
								? demoPage.about
								: demoPage?.about.map((a, index) => (
										<span key={index}>{a}</span>
									))
							):(null)}
						</p>
					</section>
					)}
					<section>
						<h2>Your next step as a candidate</h2>
						{demoPage.registrationSteps.map((e, index) => (
							<p key={index} className="mb-1">
								{e}
							</p>
						))}
					</section>
				</article>
				<Dialog
					open={isDialogOpen}
					onClose={() => setIsDialogOpen(false)}
					closeBtnText={undefined}
					floatingCloseBtn
					render={() => (
						<iframe
							title="Interview"
							className={classNames("h-full w-full")}
							src={getPreInterviewLink(jobId)}
						/>
					)}
				/>
			</main>
		</>
	);
};

export default JobLandingPage;
