import { useEffect, FC, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  children: ReactElement;
}

const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default ScrollToTop;
