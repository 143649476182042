import { cleanEnv, num, str, url } from "envalid";

const cleanedEnv = cleanEnv(process.env, {
	REACT_APP_FACEBOOK_APP_ID: num(),
	REACT_APP_GOOGLE_APP_CLIENTID: str(),
	REACT_APP_GOOGLE_CALLBACK_URL: url(),
	REACT_APP_FACEBOOK_CALLBACK_URL: url(),
	REACT_APP_HUB_API_URL: url(),
	REACT_APP_INTERVIEW_URL: url(),
	REACT_APP_OLD_INTERVIEW_URL: url(),
	REACT_APP_HEAP_APP_ID: num(),
	REACT_APP_HUBERT_PRIVACY_POLICY: url(),
});

const appEnv = {
	FACEBOOK_APP_ID: cleanedEnv.REACT_APP_FACEBOOK_APP_ID,
	GOOGLE_APP_CLIENTID: cleanedEnv.REACT_APP_GOOGLE_APP_CLIENTID,
	GOOGLE_CALLBACK_URL: cleanedEnv.REACT_APP_GOOGLE_CALLBACK_URL,
	FACEBOOK_CALLBACK_URL: cleanedEnv.REACT_APP_FACEBOOK_CALLBACK_URL,
	HUB_API_URL: cleanedEnv.REACT_APP_HUB_API_URL,
	INTERVIEW_URL: cleanedEnv.REACT_APP_INTERVIEW_URL,
	OLD_INTERVIEW_URL: cleanedEnv.REACT_APP_OLD_INTERVIEW_URL,
	HEAP_APP_ID: cleanedEnv.REACT_APP_HEAP_APP_ID,
	HUBERT_PRIVACY_POLICY: cleanedEnv.REACT_APP_HUBERT_PRIVACY_POLICY
}

export default appEnv;
