import {
	ChangeEventHandler,
	ComponentProps,
	FC,
	FocusEventHandler,
} from "react";
import { TFunction, withTranslation } from "react-i18next";
import classNames from "classnames";
import { Text } from "../elements";
import SkeletonLoader from "../elements/SkeletonLoader";

interface DropdownProps extends ComponentProps<"select"> {
	label: string;
	helpText?: string;
	options: Array<OptionType>;
	handleChange: ChangeEventHandler<any>;
	handleBlur: FocusEventHandler<any>;
	error?: string;
	touched?: boolean;
	t: TFunction<"translation", undefined>;
	loading?: boolean;
}

type OptionType = {
	name: string;
	value: string | number;
};

const Dropdown: FC<DropdownProps> = ({
	label,
	name,
	id,
	placeholder,
	helpText,
	options,
	handleChange,
	handleBlur,
	value,
	error,
	touched,
	t,
	loading,
}) =>
	loading ? (
		<SkeletonLoader height="50" />
	) : (
		<div className="form-group">
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<select
				id={id}
				onChange={handleChange}
				onBlur={handleBlur}
				name={name}
				value={value}
				className={classNames("form-select", error && touched && "error")}
			>
				<option value="" disabled>
					{placeholder}
				</option>
				{options.map((option) => (
					<option value={option.value} key={option.name}>
						{option.name}
					</option>
				))}
			</select>
			{helpText && (
				<Text size="small" block className="help-text">
					{helpText}
				</Text>
			)}
			{error && touched && (
				<Text size="small" block className="form-error">
					{t(error)}
				</Text>
			)}
		</div>
	);

Dropdown.defaultProps = {
	id: "",
	helpText: "",
	error: "",
	touched: false,
	loading: false,
};

export default withTranslation()(Dropdown);
