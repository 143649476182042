import { useFormik } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Text } from "../elements";
import { Password } from "../forms";
import { forgotPasswordFormSchema as validationSchema } from "../../utils/validationSchemas";
import { LargeAlertProps } from "../elements/LargeAlert";
import { SET_PASSWORD_BY_CODE } from "../../behavior/mutations/auth.mutation";
import { PageState } from "../../constants";

interface ForgotPasswordFormProps {
	setShowAlert: Dispatch<SetStateAction<LargeAlertProps>>;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ setShowAlert }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const [setPassword, { loading }] = useMutation(SET_PASSWORD_BY_CODE);

	const initialValues = {
		newPassword: "",
		confirmNewPassword: "",
	};

	const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async (props: typeof initialValues) => {
				const oobCode = params.get("oobCode");
				const email = params.get("email");
				if (oobCode && email) {
					const response = await setPassword({
						variables: { code: oobCode, newPassword: props.newPassword },
					});

					// eslint-disable-next-line no-underscore-dangle
					const type = response.data.setPasswordByCode.__typename;
					if (type === "WeakPassword") {
						toast.error(
							t("ForgotPassword.ResetYourPassword.Error_WeakPassword")
						);
						return;
					}
					if (type === "InvalidCode") {
						setShowAlert((prevState) => ({
							...prevState,
							title: t(
								"ForgotPassword.ResetYourPassword.Error_InvalidCode_Title"
							),
							subtitle: t(
								"ForgotPassword.ResetYourPassword.Error_InvalidCode_Subtitle"
							),
							type: "warning",
						}));
						return;
					}

					const redirectUrl = params.get("redirectUrl");
					if (response.data?.setPasswordByCode.payload) {
						if (redirectUrl) {
							window.location.replace(decodeURIComponent(redirectUrl));
							return;
						}
						const encodedEmail = encodeURIComponent(email);
						navigate(
							`/?state=${PageState.PASSWORD_RESET}&email=${encodedEmail}`,
							{ replace: true }
						);
					} else {
						toast.error(
							t("ForgotPassword.ResetYourPassword.Error_ResetPasswordFailed")
						);
					}
				} else {
					toast.error(
						t("ForgotPassword.ResetYourPassword.Error_MalformedResetUrl")
					);
				}
			},
		});

	return (
		<form className="forgot-password-form-wrapper" onSubmit={handleSubmit}>
			<Password
				label={t("ForgotPassword.ResetYourPassword.Label_NewPassword")}
				name="newPassword"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.newPassword}
				error={errors.newPassword}
				touched={touched.newPassword}
			/>
			<Password
				label={t("ForgotPassword.ResetYourPassword.Label_ConfirmNewPassword")}
				name="confirmNewPassword"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.confirmNewPassword}
				error={errors.confirmNewPassword}
				touched={touched.confirmNewPassword}
			/>
			<Button fullWidth submit className="mt-2" disabled={loading}>
				{t("ForgotPassword.ResetYourPassword.ButtonText_SetNewPassword")}
			</Button>
			<div className="sign-in-instead-button-wrapper">
				<Text>
					{t(
						"ForgotPassword.ResetYourPassword.ButtonText_RememberYourPassword"
					)}
				</Text>
				<Button link secondary className="custom-btn" to="/">
					{t("ForgotPassword.ResetYourPassword.ButtonText_SignInInstead")}
				</Button>
			</div>
		</form>
	);
};

export default ForgotPasswordForm;
