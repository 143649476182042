/* eslint-disable implicit-arrow-linebreak */
import * as Yup from 'yup';

export const yourDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required(
    'MyProfile.MyDetails.Error_FirstName_Required',
  ),
  lastName: Yup.string().required(
    'MyProfile.MyDetails.Error_LastName_Required',
  ),
  email: Yup.string()
    .required('MyProfile.MyDetails.Error_Email_Required')
    .email('MyProfile.MyDetails.Error_Email_Invalid'),
  phoneNumber: Yup.string().matches(
    /^(?:[0-9] ?){3,14}[0-9]$/g,
    'YourProfile.YourDetails.Error_PhoneNumber_Invalid',
  ),
});

export const getChangePasswordSchema = (currentPassword: boolean) => {
  if (currentPassword) {
    return Yup.object().shape({
      currentPassword: Yup.string().required(
        'MyProfile.ChangePassword.Error_CurrentPassword_Required',
      ),
      newPassword: Yup.string().required(
        'MyProfile.ChangePassword.Error_NewPassword_Required',
      ).min(6, 'MyProfile.ChangePassword.Error_NewPassword_6_Characters'),
      repeatNewPassword: Yup.string()
        .required('MyProfile.ChangePassword.Error_RepeatNewPassword_Required')
        .oneOf(
          [Yup.ref('newPassword'), null],
          'MyProfile.ChangePassword.Error_RepeatNewPassword_DoesntMatch',
        ),
    });
  }
  return Yup.object().shape({
    newPassword: Yup.string().required(
      'MyProfile.ChangePassword.Error_NewPassword_Required',
    ).min(6, 'MyProfile.ChangePassword.Error_NewPassword_6_Characters'),
    repeatNewPassword: Yup.string()
      .required('MyProfile.ChangePassword.Error_RepeatNewPassword_Required')
      .oneOf(
        [Yup.ref('newPassword'), null],
        'MyProfile.ChangePassword.Error_RepeatNewPassword_DoesntMatch',
      ),
  });
};
