import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import MiniLogoImage from "../../../assets/images/hubert-logo-mini.png";
import { Heading, LargeAlert } from "../../../components/elements";
import { LargeAlertProps } from "../../../components/elements/LargeAlert";
import LoginForm from "../../../components/login/LoginForm";
import SocialMediaLogin from "../../../components/login/SocialMediaLogin";
import { BenefitsBox } from "../../../components/registration";
import { PageState, ProviderId, TPageState } from "../../../constants";
import {
	LoginHeapType,
	LoginInterviewCompletedHeapType,
	LoginInvitationEmailHeapType,
} from "../../../types/heapId";
import { Invitation } from "../../../types/invitation";
import "../Login.scss";

interface InviteEmailSignInProps {
	invitation?: Invitation | null;
	postLoginEvent?: (token?: string) => void;
	state: TPageState;
	heapIds:
		| LoginHeapType
		| LoginInvitationEmailHeapType
		| LoginInterviewCompletedHeapType;
}

const InviteEmailSignIn: FC<InviteEmailSignInProps> = ({
	invitation,
	postLoginEvent,
	state,
	heapIds,
}) => {
	const { t } = useTranslation();
	const [showAlert, setShowAlert] = useState<LargeAlertProps>({
		title: "",
		subtitle: "",
		type: "info",
	});

	const [searchParams] = useSearchParams();
	const email = searchParams.get("email");

	const checkSignInProvider = (selectedProvider: string): boolean => {
		const providerData = invitation?.invitee.providerData;
		if (providerData) {
			if (providerData.length > 0) {
				const selected = providerData.find(
					(x) => x.providerId === selectedProvider
				);
				if (selected) {
					return true;
				}

				switch (providerData[0].providerId) {
					case ProviderId.PASSWORD:
						toast.info("Please sign in using Password.");
						break;
					case ProviderId.GOOGLE:
						toast.info("Please sign in using Google.");
						break;
					case ProviderId.FACEBOOK:
						toast.info("Please sign in using Facebook.");
						break;
					default:
						toast.info("Unsupported provider");
						break;
				}
				return false;
			}
		}
		toast.info("Unsupported provider");
		return false;
	};

	return (
		<div className="login-invite-email-wrapper">
			<div className="left-section">
				<Heading level="h2" light>
					{invitation?.job?.title}
				</Heading>
				<Heading level="h4">{t("LoginInviteEmail.Subtitle")}</Heading>
				<BenefitsBox />
			</div>
			<div className="right-section">
				<img
					className="hubert-logo-mini"
					src={MiniLogoImage}
					alt="Hubert logo mini"
				/>
				<Heading level="h4">{t("Login.LoginTitle")}</Heading>
				{showAlert.title ? (
					<LargeAlert
						title={showAlert.title}
						subtitle={showAlert.subtitle}
						type={showAlert.type}
					/>
				) : (
					<div>
						<LoginForm
							setShowAlert={setShowAlert}
							preLoadedEmail={email}
							state={state}
							validateProvider={invitation ? checkSignInProvider : () => true}
							invitation={invitation}
							heapIds={heapIds}
							postLoginEvent={postLoginEvent}
						/>
						<div className="or-section">
							<div className="or-seperator-line" />
							<div className="or-text">{t("Login.OrSeparator")}</div>
						</div>
						<SocialMediaLogin
							state={state}
							validateProvider={invitation ? checkSignInProvider : () => true}
							invitation={invitation}
							heapIds={heapIds}
							preLoadedEmail={email}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

InviteEmailSignIn.defaultProps = {
	invitation: null,
	postLoginEvent: () => null,
};

export default InviteEmailSignIn;
