import { FC } from "react";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { Heading, HorizontalLine, Text } from "../../elements";
import LatestJobPostings from "../../dashboard/latestJobPostings";
import AlertInfoLink from "./AlertInfoLink";
import Question from "./Question";
import { Feedback } from "../../../types/application";
import { textsKeysNoQuestions, textsKeysQuestions } from "./textkeyObjects";

interface HowToImproveAtInterviewsProps {
	scoreType: "gotPotential" | "greatMatch" | "okMatch" | "notClearMatch";
	questions?: boolean;
	feedbacks: Feedback[];
	loading?: boolean;
}

const HowToImproveAtInterviews: FC<HowToImproveAtInterviewsProps> = ({
	scoreType,
	questions,
	feedbacks,
	loading,
}) => {
	const { t } = useTranslation();
	const texts = questions ? textsKeysQuestions : textsKeysNoQuestions;

	return (
		<div className="how-to-improve-at-interviews-wrapper">
			<Text size="large" weight="semibold">
				{t("Interview.HowToImproveAtInterviews.Title")}
			</Text>
			<HorizontalLine weight="semi-dark" />
			<Heading level="h4" light className="mt-5 mb-4" loading={loading}>
				{t(
					questions
						? "Interview.HowToImproveAtInterviews.Subtitle_Questions"
						: "Interview.HowToImproveAtInterviews.Subtitle_NoQuestions"
				)}
			</Heading>
			<Text className="mb-4" block loading={loading}>
				{t(texts[scoreType].description)}
			</Text>
			{texts[scoreType].descriptionSecondary && (
				<Text className="mb-4" block loading={loading}>
					{t(texts[scoreType].descriptionSecondary)}
				</Text>
			)}
			{questions &&
				feedbacks.map((feedback, index) => (
					<Question
						key={index}
						question={DOMPurify.sanitize(feedback.question)}
						answer={DOMPurify.sanitize(feedback.answer)}
					/>
				))}
			{!loading && <AlertInfoLink />}
			<LatestJobPostings />
		</div>
	);
};

HowToImproveAtInterviews.defaultProps = {
	questions: false,
	loading: false,
};

export default HowToImproveAtInterviews;
