import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Image, Text } from '../elements';
import { Content } from '../../types/content';

interface ArticleCardProps {
  className?: string;
  article: Content;
  to: string;
}

const ArticleCard: FC<ArticleCardProps> = ({ className, article, to }) => (
  <Link className={classNames('article-card', className)} to={to}>
    <Image className="article-image" src={article.featureImage ?? ''} alt={article.featureImageAlt ?? ''} skeletonUntilFullLoad />
    <div className="article-tag">
      <span>{article.primaryTag}</span>
    </div>
    <Text block weight="medium" size="large">
      {article.title}
    </Text>
  </Link>
);

ArticleCard.defaultProps = {
  className: '',
};

export default ArticleCard;
