import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface ToolTipProps {
  message: string;
  className?: string;
  children: ReactNode;
  align?: 'items-start' | 'items-center' | 'items-end';
}

const ToolTip: FC<ToolTipProps> = ({ message, className, align, children }) => (
  <div className={classNames('tool-tip wrapper', 'group', align, className)}>
    {children}
    <div className={classNames('tool-tip message-container')}>
      <span className="tool-tip message">{message}</span>
    </div>
  </div>
);

ToolTip.defaultProps = {
  className: '',
  align: 'items-center',
};

export default ToolTip;
