import { FC } from 'react';
import { Heading, HorizontalLine, Image, Text } from '../elements';

interface HeadingAndImageSectionProps {
  tag?: string;
  headingText?: string;
  image?: string;
  author?: string;
  datePosted?: string;
  loading?: boolean;
}

const HeadingAndImageSection: FC<HeadingAndImageSectionProps> = ({
  tag,
  headingText,
  image,
  author,
  datePosted,
  loading,
}) => (
  <>
    <div className="heading-section">
      <div className="article-tag">
        <span>{tag}</span>
      </div>
      <Heading level="h1" loading={loading}>{headingText}</Heading>
    </div>
    <div className="image-section">
      <Image src={image} alt={headingText} className="article-image" skeletonUntilFullLoad />
      <HorizontalLine weight="semi-dark" />
      <div className="share-and-author-section">
        <Text weight="light" block loading={loading}>
          {`${author} | ${datePosted}`}
        </Text>
      </div>
    </div>
  </>
);

HeadingAndImageSection.defaultProps = {
  author: '',
  headingText: '',
  tag: '',
  image: '',
  datePosted: '',
  loading: false,
};

export default HeadingAndImageSection;
