import { FallbackProps } from "react-error-boundary";
import ErrorPage from "..";

const CriticalErrorPage = (_: Partial<FallbackProps>) => {
	return (
		<ErrorPage
			title="CRIT: A critical error occured."
			description="We've reported this to our analytics. In the meantime please try contacting support"
			hideNextSteps={false}
		/>
	);
};

export default CriticalErrorPage;
