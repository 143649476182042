export function setCookie(
  name: string,
  value: string | null,
  daysToLive?: number | null,
) {
  const date = new Date();

  if (daysToLive) {
    date.setTime(date.getTime() + daysToLive * 24 * 60 * 60 * 1000);
  } else if (daysToLive === 0) {
    date.setTime(date.getTime() - 10);
  } else {
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  }
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export function deleteCookie(name: string) {
  setCookie(name, null, 0);
}

export function getCookie(name: string) {
  const cDecoded = decodeURIComponent(document.cookie);
  const cArray = cDecoded.split('; ');
  let result = null;

  cArray.forEach(element => {
    if (element.indexOf(name) === 0) {
      result = element.substring(name.length + 1);
    }
  });
  return result;
}
