interface InstantApplyProps {
	interviewType: "instant-apply";
	jobId?: string;
}

type UseInterviewLinkProps = InstantApplyProps;

const useInterviewLink = (props: NonNullable<UseInterviewLinkProps>) => {
	const interviewLinkDomain =
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_INTERVIEW_URL
			: process.env.REACT_APP_LOOPBACK_ADDRESS;

	if (props.interviewType === "instant-apply") {
		const { jobId } = props as InstantApplyProps;

		if (!jobId) return undefined;

		return new URL(`${interviewLinkDomain}/job/${jobId}`);
	}
};

export default useInterviewLink;
