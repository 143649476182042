import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from 'react-feather';
import { Button, Heading, HorizontalLine, Image, Text } from '../../elements';
import ScorePositiveIcon from '../../../assets/images/score-positive-icon.png';
import ScoreNegativeIcon from '../../../assets/images/score-negative-icon.png';

interface ScoreBoxProps {
  scoreBoxVariant: 'professionalExperiences' | 'qualifyingRequirements';
  variant: boolean;
  buttonText?: string;
  buttonLink?: string;
}

const scoreBoxTypes = {
  professionalExperiences: {
    title: 'Interview.ScoreBox.ProfessionalExperiences',
    positiveTitle: 'Interview.ScoreBox.GreatResults_Title',
    positiveDescription: 'Interview.ScoreBox.GreatResults_Description',
    negativeTitle: 'Interview.ScoreBox.NotQualified_Title',
    negativeDescription: 'Interview.ScoreBox.NotQualified_Description',
  },
  qualifyingRequirements: {
    title: 'Interview.ScoreBox.QualifyingRequirements',
    positiveTitle: 'Interview.ScoreBox.MeetingRequirements_Title',
    positiveDescription: 'Interview.ScoreBox.MeetingRequirements_Description',
    negativeTitle: 'Interview.ScoreBox.NotAllExpectationsMet_Title',
    negativeDescription: 'Interview.ScoreBox.NotAllExpectationsMet_Description',
  },
};

const ScoreBox: FC<ScoreBoxProps> = ({
  scoreBoxVariant,
  variant,
  buttonText,
  buttonLink,
}) => {
  const { t } = useTranslation();

  return (
    <div className="score-box-wrapper">
      <Text size="large" weight="semibold">
        {t(scoreBoxTypes[scoreBoxVariant].title)}
      </Text>
      <HorizontalLine weight="semi-dark" />
      {variant ? (
        <>
          <div className="title-section">
            <Image
              src={ScorePositiveIcon}
              alt="Positive score icon"
              className="score-icon"
            />
            <Heading level="h4">
              {t(scoreBoxTypes[scoreBoxVariant].positiveTitle)}
            </Heading>
          </div>
          <Text>{t(scoreBoxTypes[scoreBoxVariant].positiveDescription)}</Text>
        </>
      ) : (
        <>
          <div className="title-section">
            <Image
              src={ScoreNegativeIcon}
              alt="Negative score icon"
              className="score-icon"
            />
            <Heading level="h4">
              {t(scoreBoxTypes[scoreBoxVariant].negativeTitle)}
            </Heading>
          </div>
          <Text>{t(scoreBoxTypes[scoreBoxVariant].negativeDescription)}</Text>
        </>
      )}
      {buttonText && (
        <div className="btn-container">
          <Button secondary link to={buttonLink}>
            {buttonText}
            <ArrowRight size={20} className="ml-1.5" />
          </Button>
        </div>
      )}
    </div>
  );
};

ScoreBox.defaultProps = {
  buttonText: '',
  buttonLink: '',
};

export default ScoreBox;
