import { useRollbar } from '@rollbar/react';
import { FC } from 'react';
import { ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import client from '../../../behavior/apolloClient';
import { GET_VIEWER_INFORMATION } from '../../../behavior/queries/viewer.query';
import { ApplicationStatus, PageRoute, PageState } from '../../../constants';
import Application from '../../../types/application';
import { generateLiteralDay } from '../../../utils/dateUtilities';
import { generateInterviewUrl } from '../../../utils/urlUtility';
import { Button, Text } from '../../elements';
import StartInterviewButton from '../startInterviewButton/StartInterviewButton';

interface InterviewBoxProps {
  row: Application;
  loading: boolean;
}

const InterviewBox: FC<InterviewBoxProps> = ({ row, loading }) => {
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const navigate = useNavigate();

  const redirectToInterview = () => {
    const { viewer } = client.readQuery({
      query: GET_VIEWER_INFORMATION,
    });

    if (!row.interview) {
      return;
    }

    const { id } = row.interview;

    const displayName = viewer.displayName ?? viewer.email;
    const interviewStartUrl = generateInterviewUrl(
      id,
      `${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`
    );
    navigate(PageRoute.PRE_INTERVIEW, {
      state: {
        interviewUrl: interviewStartUrl,
        invitation: { invitee: { displayName } },
      },
    });
  };

  const interviewEnded = (() => {
    if (row.interview) {
      if (row.interview.status !== ApplicationStatus.TO_DO) {
        return true;
      }
    } else {
      rollbar.debug('Interview not found in Application Id', row.id);
    }
    return false;
  })();
  const prefix = interviewEnded ? null : t('Common.MyInterviews.ApplicationDate_Invited');

  return (
    <div className="interview-box-wrapper">
      <div className="first-section">
        <Text weight="medium" block>
          {row.job?.company}
        </Text>
        <Text weight="light" block>
          {row.job?.title}
        </Text>
        <Text size="small" block>
          {generateLiteralDay(row.createdAt, prefix, t)}
        </Text>
        {row?.interview?.status === ApplicationStatus.NOT_SCORED && (
          <Text className="text-gray-500">
            {t('Common.MyInterviews.TableBody_NotScored')}
          </Text>
        )}
      </div>
      <div className="second-section">
        {row.interview?.status === ApplicationStatus.TO_DO ? (
          <StartInterviewButton
            btnLabel={t('Common.MyInterviews.ButtonText_StartInterview')}
            applicationId={row.id}
            interviewId={row.interview.id}
            hidden={loading}
            redirectToInterview={() => redirectToInterview()}
            oldInterviewFlow={row.job?.candidateApp === true}
          />
        ) : (
          <Button
            size="small"
            secondary
            to={`${PageRoute.INTERVIEWS}/${row.id}`}
            disabled={row.interview?.status !== ApplicationStatus.SCORED}
          >
            {row.interview?.status !== ApplicationStatus.SCORED ?
              t('Common.MyInterviews.ButtonText_NotScored') :
              t('Common.MyInterviews.ButtonText_Details') }
            <ArrowRight size={20} className="ml-1.5" />
          </Button>
        )}
      </div>
    </div>
  );
};


export default InterviewBox;
