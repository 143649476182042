interface ConditionalComponentProps {
	condition: boolean;
	wrapper: (children: JSX.Element) => JSX.Element;
	children: JSX.Element;
}

const ConditionalComponent = ({
	condition,
	wrapper,
	children,
}: ConditionalComponentProps): JSX.Element => {
	return condition ? wrapper(children) : children;
};

export default ConditionalComponent;
