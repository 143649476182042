import { getAccessToken } from "behavior/security/auth.service";
import { deserialize, serialize } from "hooks/useDataSerializer";
import { LoginStateWithAction } from "types/authentication";
import appEnv from "./appEnv";

class EncodeError extends Error {
	readonly input: string;

	constructor(message: string, input: string, options: { cause: Error }) {
		super(message, options);
		this.input = input;
	}
}

const encodeParam = (payload: any): string => {
	try {
		const encodedObject = serialize(payload);
		return encodeURIComponent(encodedObject);
	} catch (err) {
		throw new EncodeError("encodeParam - Failed to encode payload.", payload, {
			cause: err as Error,
		});
	}
};

const decodeStateParam = (payload: string): LoginStateWithAction => {
	return deserialize<LoginStateWithAction>(decodeURIComponent(payload));
};

const generateInterviewUrlRaw = (
	interviewId: string,
	redirectLocalPath: string,
	accessToken?: string | null
) => {
	if (!process.env.REACT_APP_INTERVIEW_URL)
		throw new Error('Missing base url for interviews');
	
	const interviewUrl = new URL(`${process.env.REACT_APP_INTERVIEW_URL}/${interviewId}`);
	if (accessToken)
		interviewUrl.searchParams.append('token', accessToken);
		
	const redirectUrl = `${window.location.origin}${redirectLocalPath}`;
	interviewUrl.searchParams.append('redirectUrl', redirectUrl);

	return interviewUrl.toString();
	//return `${interviewUrl}/${interviewId}?token=${accessToken}&redirectUrl=${redirectUrl}`;
};


const generateInterviewUrl = (
	interviewId: string,
	redirectLocalPath: string,
) => {
	return generateInterviewUrlRaw(interviewId, redirectLocalPath, getAccessToken());
};

const generateGoogleSignInUrl = (state: string) => {
	const googleSignInUrl = new URL("https://accounts.google.com/o/oauth2/auth");
	googleSignInUrl.searchParams.append(
		"scope",
		"https://www.googleapis.com/auth/userinfo.email" +
			" https://www.googleapis.com/auth/userinfo.profile"
	);

	googleSignInUrl.searchParams.append("response_type", "code");
	googleSignInUrl.searchParams.append(
		"redirect_uri",
		appEnv.GOOGLE_CALLBACK_URL
	);
	googleSignInUrl.searchParams.append("client_id", appEnv.GOOGLE_APP_CLIENTID);
	googleSignInUrl.searchParams.append("state", state);
	return googleSignInUrl;
};

const generateFacebookSignInUrl = (state: string) => {
	const facebookSignInUrl = new URL(
		"https://www.facebook.com/v14.0/dialog/oauth"
	);
	facebookSignInUrl.searchParams.append(
		"client_id",
		appEnv.FACEBOOK_APP_ID.toString()
	);
	facebookSignInUrl.searchParams.append("response_type", "token");
	facebookSignInUrl.searchParams.append(
		"redirect_uri",
		appEnv.FACEBOOK_CALLBACK_URL
	);
	facebookSignInUrl.searchParams.append("scope", "email");
	facebookSignInUrl.searchParams.append("state", state);
	return facebookSignInUrl;
};

export {
	decodeStateParam,
	encodeParam,
	generateFacebookSignInUrl,
	generateGoogleSignInUrl,
	generateInterviewUrl,
	generateInterviewUrlRaw
};

