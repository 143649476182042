import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, HorizontalLine } from '../../elements';
import AssessedMatch from './AssessedMatch';
import ScoreBox from './ScoreBox';
import { ProfessionalExperience } from '../../../constants';

export interface YourScoreProps {
  assessedMatch: 'gotPotential' | 'greatMatch' | 'okMatch' | 'notClearMatch';
  professionalExperience: string;
  qualified: boolean;
  loading?: boolean;
}

const YourScore: FC<YourScoreProps> = ({
  assessedMatch,
  professionalExperience,
  qualified,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="your-score-wrapper">
      <Heading level="h5">{t('Interview.MyResult.Title')}</Heading>
      <HorizontalLine weight="semi-dark" />
      <AssessedMatch scoreType={assessedMatch} loading={loading} />
      {!loading && (
        <div className="score-boxes-wrapper">
          <ScoreBox
            scoreBoxVariant="professionalExperiences"
            variant={professionalExperience === ProfessionalExperience.OK_MATCH}
          />
          <ScoreBox
            scoreBoxVariant="qualifyingRequirements"
            variant={qualified}
          />
        </div>
      )}
    </div>
  );
};

YourScore.defaultProps = {
  loading: false,
};

export default YourScore;
