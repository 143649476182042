import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LoginStateWithAction } from "types/authentication";
import FacebookIcon from "../../assets/images/facebook-logo.png";
import GoogleIcon from "../../assets/images/google-logo.png";
import { PageState, TPageState } from "../../constants";
import {
	RegistrationHeapType,
	RegistrationInterviewCompletedHeapType,
	RegistrationInvitationEmailHeapType,
} from "../../types/heapId";
import { Invitation } from "../../types/invitation";
import {
	encodeParam,
	generateFacebookSignInUrl,
	generateGoogleSignInUrl,
} from "../../utils/urlUtility";
import { Button } from "../elements";

interface SocialMediaRegistrationProps {
	invitation?: Invitation | null;
	heapIds:
		| RegistrationHeapType
		| RegistrationInvitationEmailHeapType
		| RegistrationInterviewCompletedHeapType;
}

const SocialMediaRegistration: FC<SocialMediaRegistrationProps> = ({
	invitation,
	heapIds,
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const postInvitationStates = [
		PageState.INVITATION_POST_INTERVIEW_SIGN_UP,
		PageState.INVITATION_POST_INTERVIEW_SIGN_IN,
	];
	const postJobApplyStates = [
		PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP,
		PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN,
	];

	const extractToken = () => {
		const params = new URLSearchParams(location.search);
		return params.get("token");
	};

	const googleSignUp = async () => {
		const params = new URLSearchParams(location.search);
		const pageState = params.get("state");
		const email = params.get("email");

		let loginState: LoginStateWithAction = { action: PageState.SIGN_UP };
		const token = extractToken();
		if (pageState === PageState.INVITATION_SIGN_UP) {
			loginState = {
				action: pageState,
				...(token && { token }),
				invitation,
			};
		} else if (postJobApplyStates.find((x) => x === pageState)) {
			if (email)
				loginState = {
					action: pageState as TPageState,
					email,
				};
		} else if (postInvitationStates.find((x) => x === pageState)) {
			loginState = {
				action: pageState as TPageState,
				invitation,
				...(token && { token }),
			};
		}

		const encodedGoogleSignUpState = encodeParam(loginState);
		const googleSignInUrl = generateGoogleSignInUrl(encodedGoogleSignUpState);

		if (postInvitationStates.find((x) => x === pageState) && invitation) {
			googleSignInUrl.searchParams.append(
				"login_hint",
				invitation.invitee.email
			);
		} else if (postJobApplyStates.find((x) => x === pageState) && email) {
			googleSignInUrl.searchParams.append("login_hint", email);
		}

		window.location.replace(googleSignInUrl);
	};

	const facebookSignUp = async () => {
		const params = new URLSearchParams(location.search);
		const pageState = params.get("state");
		const token = extractToken();
		let loginState: LoginStateWithAction = { action: PageState.SIGN_UP };

		if (pageState === PageState.INVITATION_SIGN_UP) {
			loginState = {
				action: pageState,
				...(token && { token }),
				invitation,
			};
		} else if (postJobApplyStates.find((x) => x === pageState)) {
			const email = params.get("email");
			if (email)
				loginState = {
					action: pageState as TPageState,
					email,
				};
		} else if (postInvitationStates.find((x) => x === pageState)) {
			loginState = {
				action: pageState as TPageState,
				invitation,
				...(token && { token }),
			};
		}

		const encodedFacebookSignUpState = encodeParam(loginState);

		const facebookSignInUrl = generateFacebookSignInUrl(
			encodedFacebookSignUpState
		);
		window.location.replace(facebookSignInUrl);
	};

	return (
		<div className="social-media-registration-wrapper">
			<Button
				id={heapIds.BUTTON_SIGNUPGOOGLE}
				secondary
				fullWidth
				className="mb-4"
				imgIcon={GoogleIcon}
				onClick={googleSignUp}
			>
				{t("Registration.ButtonText_SignUpWithGoogle")}
			</Button>
			{/* <Button secondary fullWidth className="mb-4" imgIcon={AppleIDIcon}>
        {t('Registration.ButtonText_SignUpWithAppleID')}
      </Button> */}
			{/* <Button
				id={heapIds.BUTTON_SIGNUPFACEBOOK}
				secondary
				fullWidth
				imgIcon={FacebookIcon}
				onClick={facebookSignUp}
			>
				{t("Registration.ButtonText_SignUpWithFacebook")}
			</Button> */}
		</div>
	);
};

SocialMediaRegistration.defaultProps = {
	invitation: null,
};

export default SocialMediaRegistration;
