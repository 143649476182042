import { FC, ComponentProps } from "react";

interface RadioProps extends ComponentProps<"input"> {
	label: string;
	name: string;
	defaultValue: string;
}

const Radio: FC<RadioProps> = ({
	label,
	name,
	value,
	onChange,
	onBlur,
	defaultValue,
}) => (
	<div className="form-group form-radio">
		<input
			type="radio"
			value={value}
			name={name}
			id={label}
			onChange={onChange}
			onBlur={onBlur}
			defaultChecked={value === defaultValue}
		/>
		<label htmlFor={label} className="form-label-radio">
			{label}
		</label>
	</div>
);

export default Radio;
