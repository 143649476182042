import {
	ChangeEventHandler,
	ComponentProps,
	FC,
	FocusEventHandler,
} from "react";
import { TFunction, withTranslation } from "react-i18next";
import classNames from "classnames";
import { Text } from "../elements";
import SkeletonLoader from "components/elements/SkeletonLoader";

interface TextboxProps extends ComponentProps<"input"> {
	label?: string;
	helpText?: string;
	handleChange: ChangeEventHandler<any>;
	handleBlur: FocusEventHandler<any>;
	error?: string;
	touched?: boolean;
	t: TFunction<"translation", undefined>;
	loading?: boolean;
}

const Textbox: FC<TextboxProps> = ({
	type,
	label,
	name,
	id,
	placeholder,
	helpText,
	disabled,
	handleChange,
	handleBlur,
	value,
	error,
	touched,
	t,
	loading,
}) =>
	loading ? (
		<SkeletonLoader height="50" />
	) : (
		<div className="form-group">
			<label
				htmlFor={name}
				className={classNames("form-label", !label && "h-5")}
			>
				{label}
			</label>
			<input
				id={id}
				className={classNames(
					"form-input",
					error && touched && "error",
					disabled && "disabled"
				)}
				type={type}
				name={name}
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
				value={value}
				autoComplete="off"
				disabled={disabled}
			/>
			{helpText && (
				<Text size="small" block className="help-text">
					{helpText}
				</Text>
			)}
			{error && touched && (
				<Text size="small" block className="form-error">
					{t(error)}
				</Text>
			)}
		</div>
	);

Textbox.defaultProps = {
	type: "text",
	label: "",
	id: "",
	placeholder: "",
	helpText: "",
	disabled: false,
	error: "",
	touched: false,
	loading: false,
};

export default withTranslation()(Textbox);
