import { FC, MouseEvent, memo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, LogOut, User } from "react-feather";
import classNames from "classnames";
import LanguageSelector from "./LanguageSelector";
import { Text } from "../elements";
import { useOnClickOutside } from "../../utils/customHooks";
import { Viewer } from "../../types/viewer";
import { PageRoute, PageState } from "../../constants";

interface UserDropdownProps {
	isMobile: boolean;
	viewer?: Viewer;
}

const UserDropdown: FC<UserDropdownProps> = ({ isMobile, viewer }) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const [dropdownToggled, setDropdownToggled] = useState(false);
	useOnClickOutside(ref, () => setDropdownToggled(false));
	const navigate = useNavigate();
	const logout = async (e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		navigate(PageRoute.TRANSITION_PAGE, {
			state: { redirectUrl: `/?state=${PageState.LOGOUT}`, logout: true },
		});
	};

	return !isMobile ? (
		<div className="language-user-wrapper">
			<LanguageSelector language={viewer?.language} />
			<div className="user-dropdown-wrapper" ref={ref}>
				<button
					type="button"
					className={classNames("user", dropdownToggled && "expanded")}
					onClick={() => setDropdownToggled(!dropdownToggled)}
				>
					<Text>{viewer?.displayName ?? viewer?.email}</Text>
					{dropdownToggled ? <ChevronUp /> : <ChevronDown />}
				</button>
				<div className={classNames("dropdown", dropdownToggled && "expanded")}>
					<Link to={PageRoute.PROFILE} className="my-profile">
						<User size={16} />
						<Text block>{t("Header.MyProfile")}</Text>
					</Link>
					<div className="horizontal-line" />
					<Link to="/" className="log-out" onClick={logout}>
						<LogOut size={16} />
						<Text block>{t("Header.LogOut")}</Text>
					</Link>
				</div>
			</div>
		</div>
	) : (
		<div className="user-mobile-wrapper" ref={ref}>
			<div className="user-name">{viewer?.displayName ?? viewer?.email}</div>
			<Link to={PageRoute.PROFILE} className="my-profile">
				{t("Header.MyProfile")}
			</Link>
			<Link to="/" className="log-out" onClick={logout}>
				{t("Header.LogOut")}
			</Link>
		</div>
	);
};

UserDropdown.defaultProps = {
	viewer: undefined,
};

export default memo(UserDropdown);
