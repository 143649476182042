import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import { FC } from "react";
import { ArrowLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GET_CONTENT } from "../../behavior/queries/content.query";
import HeadingAndImageSection from "../../components/article/HeadingAndImageSection";
import { Button, Text } from "../../components/elements";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Content } from "../../types/content";
import { getLocalDate } from "../../utils/dateUtilities";
import "./Article.scss";
import { PageRoute } from "../../constants";

const Article: FC = () => {
	const { t } = useTranslation();
	const params = useParams();
	const { data, loading } = useQuery<{ content: Content }>(GET_CONTENT, {
		variables: {
			contentId: params.id,
		},
	});

	// eslint-disable-next-line no-underscore-dangle
	return (
		<>
			<Header type="LoggedIn" />
			<div className="article-page-wrapper">
				<Button secondary link to={PageRoute.LEARN_WITH_HUBERT} className="back-button">
					<ArrowLeft size={20} className="mr-2" />
					<div>{t("Article.ButtonText_BackToAllArticles")}</div>
				</Button>
				{data?.content.__typename === "RecordNotFound" ? (
					<>No Content Found</>
				) : (
					<>
						<HeadingAndImageSection
							tag={data?.content.primaryTag}
							headingText={data?.content.title}
							image={data?.content.featureImage}
							author={data?.content.primaryAuthor}
							datePosted={getLocalDate(data?.content.publishedOn)}
							loading={loading}
						/>
						<div className="article-content-section">
							<Text block html loading={loading}>
								{DOMPurify.sanitize(data?.content.html ?? "")}
							</Text>
						</div>
					</>
				)}
			</div>
			<Footer />
		</>
	);
};

export default Article;
