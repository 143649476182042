import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_VIEWER_INFORMATION } from "../../behavior/queries/viewer.query";
import { Heading } from "../../components/elements";
import Header from "../../components/header/Header";
import { YourDetails, ChangePassword } from "../../components/yourProfile";
import { Viewer } from "../../types/viewer";
import "./YourProfile.scss";

const YourProfile = () => {
	const { t } = useTranslation();
	const { data, loading, refetch } = useQuery<{ viewer: Viewer }>(
		GET_VIEWER_INFORMATION
	);
	const [viewer, setViewer] = useState<Viewer>();
	const [existingPassword, setExistingPassword] = useState<boolean>(false);
	const [primaryProvider, setPrimaryProvider] = useState("");

	const refetchData = async () => {
		await refetch();
	};

	const loadProfileDetails = async () => {
		if (!loading && data?.viewer) {
			setViewer(data.viewer);
			if (data.viewer.providerData) {
				const exists = data.viewer.providerData.find(
					(x) => x.providerId === "password"
				);
				if (!exists) {
					setPrimaryProvider(data.viewer.providerData[0].providerId);
				}
				setExistingPassword(!!exists);
			}
		}
	};

	useEffect(() => {
		loadProfileDetails();
	}, [data]);

	return (
		<>
			<Header type="LoggedIn" />
			<div className="your-profile-wrapper">
				<Heading level="h2">{t("MyProfile.Title")}</Heading>
				<div className="column-wrapper">
					<YourDetails viewer={viewer} loading={loading} />
					<ChangePassword
						existingPassword={existingPassword}
						primaryProvider={primaryProvider}
						loading={loading}
						refetch={refetchData}
					/>
				</div>
				{/* Removing as per CAND-239 */}
				{/* <div className="column-wrapper">
          <NotificationPreferences notification={viewer?.notification} loading={loading} />
        </div> */}
			</div>
		</>
	);
};

export default YourProfile;
